import AitIdir from "../images/speakers/Dr-AIT-IDIR-Karim.jpg"
import Benabadji from "../images/speakers/Dr-BENABADJI-Farid.jpg"
import Bouchouareb from "../images/speakers/Dr-BOUCHOUAREB-Mohamed-Hamza.jpg"
import Dahmane from "../images/speakers/Dr-DAHMANE-Nabila.jpg"
import Lamrani from "../images/speakers/Dr-LAMRANI-Fella.jpg"
import Larbani from "../images/speakers/DR-LARBANI-Salim.jpg"
import Lyazidi from "../images/speakers/Dr-LYAZIDI-Nabila.jpg"
import Ouslim from "../images/speakers/Dr-OUSLIM-Naima.jpg"
import Sekfali from "../images/speakers/Dr-SEKFALI-Lynda.jpg"
import Helal from "../images/speakers/HELAL-Sarah-Laboratoire-BEKER.jpg"
import Abdelaziz from "../images/speakers/Pr-ABDELLAZIZ-Rachid.jpg"
import Akif from "../images/speakers/Pr-AKIF-Nora.jpg"
import Aouameur from "../images/speakers/Pr-AOUAMEUR-Rachida.jpg"
import Ayoub from "../images/speakers/Pr-AYOUB-Soraya.jpg"
import Belkacem from "../images/speakers/Pr-BELKACEM-Ali-Ouramdane.jpg"
import Benamer from "../images/speakers/Pr-BENAMER-Mustapha.jpg"
import Benhalla from "../images/speakers/Pr-BENHALLA-Keltoum-Nafissa.jpg"
import Benmessaoud from "../images/speakers/Pr-BENMESSAOUD-Hayette.jpg"
import Benyahia from "../images/speakers/Pr-BENYAHIA-Samir.jpg"
import Berrah from "../images/speakers/Pr-BERRAH-Mahbouba.jpg"
import Boufersaoui from "../images/speakers/Pr-BOUFERSAOUI-Abderrahmane.jpg"
import Boughrarou from "../images/speakers/Pr-BOUGHRABOU-Rafika.jpg"
import Boumedine from "../images/speakers/Pr-BOUMEDINE-Sadek.jpg"
import Douagui from "../images/speakers/Pr-DOUAGUI-Habib.jpg"
import Drali from "../images/speakers/Pr-DRALI-Ouardia.jpg"
import Haouichat from "../images/speakers/Pr-HAOUICHAT-Houria-GSK.jpg"
import Lamara from "../images/speakers/Pr-LAMARA-MAHAMED-Amer.jpg"
import Liani from "../images/speakers/Pr-LIANI-Nora.jpg"
import Malki from "../images/speakers/Pr-MALKI-Ghania.jpg"
import Ouarab from "../images/speakers/Pr-OUARAB-Chafia.jpg"
import Souttou from "../images/speakers/Pr-SOUTTOU-Djamil.jpg"
import Yahi from "../images/speakers/Pr-YAHI-Nadia.jpg"
import Yala from "../images/speakers/Pr-YALA-Djamel.jpg"
import Cherbali from "../images/speakers/cherbali.jpg"
import Djenouhat from "../images/speakers/djenouhat.jpg"
import Karaouat from "../images/speakers/karaouat.jpg"
import Makhlouf from "../images/speakers/Makhlouf-Benzamit.png"
import Ouedraogo from "../images/speakers/Martial-Ouedraogo.png"
import Mazouz from "../images/speakers/mazouz.jpg"
import Denis from "../images/speakers/pr_denis_charpin.jpg"
import Lellou from "../images/speakers/Salah-Lellou.png"
import Toloba from "../images/speakers/Yakouba-Toloba.png"
import Ouali from "../images/speakers/ouali.jpg"
import Glaser from "../images/speakers/glaser.jpg"
import Mata from "../images/speakers/mata.jpg"
import Fontanet from "../images/speakers/fontanet.jpg"
import Hamzaoui from "../images/speakers/hamzaoui.jpg"
import Marcot from "../images/speakers/marcot.jpg"
import Vanlaeys from "../images/speakers/Alison-Vanlaeys.jpg"
import Hervieu from "../images/speakers/Brenda-Hervieu.jpg"
import Poree from "../images/speakers/Thierry-Poree.jpg"

const speakers = [
  {
    name: "Pr DOUAGUI Habib",
    src: Douagui,
    poste: "",
    bio: "Président de la SAAIC"
  },
  {
    name: "Pr LAMARA MAHAMED Amer",
    src: Lamara,
    poste: "",
    bio: "Vice Président de la SAAIC , Membre du conseil scientifique de l'académie du sommeil"
  },
  {
    name: "Pr ABDELLAZIZ Rachid",
    src: Abdelaziz,
    poste: "Chef de Service",
    bio: "Secrétaire Général de la SAAIC"
  },
  {
    name: "Pr HAOUICHAT Houria GSK",
    src: Haouichat,
    poste: "Medical expert GSK /ex chef de service pneumologie HCA"
  },
  {
    name: "Dr AIT IDIR Karim",
    src: AitIdir,
    poste: "Maitre de conférence A",
    bio: "Membre de la SAAIC , de la SAP , SPO ,SAGEHNP"
  },
  {
    name: "Dr BENABADJI Farid",
    src: Benabadji,
    poste: "Libéral",
    bio: "Membre de la SAAIC"
  },
  {
    name: "Dr BOUCHOUAREB Mohamed Hamza",
    src: Bouchouareb,
    poste: "Médecin Résident",
    bio: ""
  },
  {
    name: "Dr DAHMANE Nabila",
    src: Dahmane,
    poste: "Maitre Assistante",
    bio: "Membre de la SAAIC , Membre du CES"
  },
  {
    name: "Dr LAMRANI Fella",
    src: Lamrani,
    poste: "Pédiatre",
    bio: "Membre de la SAAIC, membre de la Société Algérienne de la pédiatrie"
  },
  {
    name: "Dr LARBANI Salim",
    src: Larbani,
    poste: "Medecin Spécialiste",
    bio: ""
  },
  {
    name: "Dr LYAZIDI Nabila",
    src: Lyazidi,
    poste: "Maitre Assistant",
    bio: "Membre de la SAAIC, membre du CES"
  },
  {
    name: "Dr OUSLIM Naima",
    src: Ouslim,
    poste: "Libéral",
    bio: "Membre du bureau de la SAAIC"
  },
  {
    name: "Dr SEKFALI Lynda",
    src: Sekfali,
    poste: "Maitre Assistante Pédiatrie A",
    bio: "Diplomée en Allergoloie de l'unversité de CLAUDE BERNARD Lyon , Membre du bureau SAPHYRES"
  },
  {
    name: "Mme HELAL Sarah",
    src: Helal,
    poste: "Assistante de recherche (BEKER)",
    bio: ""
  },
  {
    name: "Pr AKIF Nora",
    src: Akif,
    poste: "",
    bio: "Membre de la SAAIC , Membre du laboratoir de biogénotoxicologie et santé au travail"
  },
  {
    name: "Pr AOUAMEUR Rachida",
    src: Aouameur,
    poste: "Chef de Service",
    bio: "Membre de la société Algérienne d'anesthésie et de réanimation , membre de la société Algérienne de gériatrie , membre de la Société Algérienne de tabacologie"
  },
  {
    name: "Pr AYOUB Soraya",
    src: Ayoub,
    poste: "Chef de Service",
    bio: "Chef de département adjoint à la faculté de medecine Alger , Sous directrice sciences médicales MESRS , Membre conseil d'administrations des CHU D'alger et de l'institut pasteur d'algérie "
  },
  {
    name: "Pr BELKACEM Ali Ouramdane",
    src: Belkacem,
    poste: "Chef de Service",
    bio: "Membre de la angio oeudeme hériditaire , membre de la SAAIC , membre du CES"
  },
  {
    name: "Pr BENAMER Mustapha",
    src: Benamer,
    poste: "Chef de Service",
    bio: "Membre de la société Algérienne de médecine interne ,Président de la société Algérienne de gériaterie membre de la société national française de gastro enterrologie , membre de la société française de l'endoscopie digestive , membre de la société Algérienne d'hypertension artérielle , membre de la SAAIC"
  },
  {
    name: "Pr BENHALLA Keltoum Nafissa",
    src: Benhalla,
    poste: "Chef de Service",
    bio: "Membre de la société Algérienne de Pédiatrie"
  },
  {
    name: "Pr BENMESSAOUD Hayette",
    src: Benmessaoud,
    poste: "Chef de service",
    bio: "Membre de la société Algérienne de medecine du travail"
  },
  {
    name: "Pr BENYAHIA Samir",
    src: Benyahia,
    poste: "Chef de service ",
    bio: "Vice secrétaire général de la société Algérienne d'ORL et d'autoneurochirurgie , Membre de la société Française d'ORL"
  },
  {
    name: "Pr BERRAH Mahbouba",
    src: Berrah
  },
  {
    name: "Pr BOUFERSAOUI Abderrahmane",
    src: Boufersaoui,
    poste: "Chef d'unité de pneumologie et d'endoscopie bronchique pédiatrique",
    bio: "Vice président de la société Algérienne de pédiatrie , membre du collège des enseignants de la SAAIC, membre de la SAPHYRES"
  },
  {
    name: "Pr BOUGHRAROU Rafika",
    src: Boughrarou,
    poste: "Chef de Service",
    bio: "Membre de la société Algérienne de radiologie Membre de la société européenne de Radiologie , membre de la société européenne d'imagerie cardiaque ,Membre de la société européenne respiratoire , membre de la société Algérienne d'encologie thoracique ,membre de la SAAIC  "
  },
  {
    name: "Pr BOUMEDINE Sadek",
    src: Boumedine,
    poste: "",
    bio: "Membre fondateur  de la société Algérienne de medecine vasculaire , membre fondateur  de la société algérienne de medecine interne"
  },
  {
    name: "Pr DRALI Ouardia",
    src: Drali,
    poste: "Chef d'Unité Consultations",
    bio: "Diplôme interuniversitaire de pneumo allergologie pédiatrique Paris descartes"
  },
  {
    name: "Pr LIANI Nora",
    src: Liani,
    poste: "Chef de Service",
    bio: "Membre de la SAAIC,coordinatrice du comité national des experets en medecine du travail (MSPRH)"
  },
  {
    name: "Pr MALKI Ghania",
    src: Malki,
    poste: "Chef de Service",
    bio: "Membre de la SAAIC, Secrétaire général de la SARPHYRES"
  },
  {
    name: "Pr OUARAB Chafia",
    src: Ouarab,
    poste: "Maitre de conférence A",
    bio: "Membre de la SAAIC, membre de la SAMEV"
  },
  {
    name: "Pr SOUTTOU Djamil",
    src: Souttou,
    poste: "Chef de service",
    bio: "Membre de la SAAIC , Membre de la AALC"
  },
  {
    name: "Pr YAHI Nadia",
    src: Yahi,
    poste: "Chef de service",
    bio: "Membre la société français d'ORL et chirurgie cervico faciale , Membre de la société Algérienne d'ORL"
  },
  {
    name: "Pr YALA Djamel",
    src: Yala,
    poste: "Chef de Service",
    bio: ""
  },
  {
    name: "Dr CHERBALI Wahid ",
    src: Cherbali,
    poste: "Chef de Service",
    bio: "Membre de la société Algérienne de l'ophtalmologie , Membre de la SAAIC"
  },
  {
    name: "Pr YAKOUBA Toloba",
    src: Toloba,
    poste: "Chef de Service",
    bio: "Présidant de la société africaine d'allergologie et d'immunologie  / Responsable du Centre de COVID de Bamako\n" +
      "Président de la Société Malienne de Pneumologie              "
  },
  {
    name: "Pr DJENOUHAT Kamel",
    src: Djenouhat,
    poste: "Chef de service",
    bio: "Président de la société Algérienne d'immunologie"
  },
  {
    name: "Pr KARAOUAT Fatiha",
    src: Karaouat,
    poste: "Libérale",
    bio: "Membre de la SAAIC Co-présidente du GOA Groupe Ophta-allergologue"
  },
  {
    name: "Pr BENZAMIT Makhlouf",
    src: Makhlouf,
    poste: "",
    bio: "Membre du Conseil de la Société Méditerranéenne d'Otologie et d'Audiologie (MSOA)\n" +
      "Membre de l'Académie européenne d'otologie et d'otoneurologie (EAONO)\n" +
      "Membre de la société Politzer"
  },
  {
    name: "Pr OUEDRAOGO Martial",
    src: Ouedraogo,
    poste: "Chef de service",
    bio: "\"Chef de service de Pneumologie du CHU Yalgado Ouédraogo\n" +
      "• Coordonnateur du Diplôme d’études spéciales de pneumologie\n" +
      "• Responsable de l’unité d’enseignement de Cardio-Pneumologie à l’Université Joseph Ki\n" +
      "Zerbo\n" +
      "• Membre du collège des médecins du CHU Yalgado Ouédraogo\n" +
      "• Président du comité technique du Programme National Tuberculose du Burkina\n" +
      "• Ancien vice-président de la Société Africaine de Pneumologie de Langue Française\n" +
      "• Ancien Président de la Société Africaine d’Allergologie et d’Immunologie clinique\n"
  },
  {
    name: "Dr MAZOUZ Noureddine",
    src: Mazouz,
    poste: "Libéral",
    bio: "Trésorier de la SAAIC"
  },
  {
    name: "Dr OUALI Mohamed",
    src: Ouali,
    poste: "Privé",
    bio: "Vice président Sud de la SAAIC"
  },
  {
    name: "Pr CHARPIN Denis",
    src: Denis,
    poste: "Président de l’Association pour la Prévention de la Pollution Atmosphérique-APPA",
    bio: ""
  },
  {
    name: "Pr LELLOU Salah",
    src: Lellou,
    poste: "Chef de service",
    bio: ""
  },
  {
    name: "Dr Philippe Glaser",
    src: Glaser,
    poste: "Directeur de Recherche",
    bio: ""
  },
  {
    name: "Pr FONTANET Arnaud",
    src: Fontanet,
    poste: "Chef d’unité",
    bio: "Arnaud Fontanet est Professeur à l’Institut Pasteur et au Conservatoire National des Arts et Métiers.  Ancien interne des hôpitaux de Paris, docteur en médecine (Université Paris V) et en santé publique (Université de Harvard), il est spécialisé en épidémiologie des maladies infectieuses et tropicales. Depuis janvier 2002, il dirige l’Unité d’Épidémiologie des Maladies Émergentes à l’Institut Pasteur de Paris. Ses principaux thèmes de recherche sont les hépatites virales et les virus émergents. Arnaud Fontanet est également Directeur de l’Ecole Pasteur-Cnam de Santé Publique, et Directeur du Département de Santé Globale de l’Institut Pasteur. En 2018-9, il a été le premier titulaire de la Chaire de Santé Publique nouvellement créée au Collège de France. Depuis mars 2020, il est membre du Conseil Scientifique Covid-19 auprès du gouvernement français."
  },
  {
    name: "Pr Pedro Da Mata",
    src: Mata,
    poste: "Directeur de L'Institut Clinique d'Allergologie de Lisbonne",
    bio: ""
  },
  {
    name: "Pr MARCOT Christophe",
    src: Marcot,
    poste: "PH",
    bio: "Je suis spécialisé dans les pathologies respiratoires et je présente un intérêt pour les pathologies respiratoires en lien avec l’environnement. Je suis titulaire du DESC et de la capacité d’allergologie. Je réalise également un Master « Santé Environnement et Politique » avec l’institut d’études politiques de Strasbourg.  "
  },
  {
    name: "Pr HAMZAOUI Agnes",
    src: Hamzaoui,
    poste: "Chef de service",
    bio: "Présidente de la STMRA (Société Tunisienne des Maladies Respiratoires)"
  },
  {
    name: "Dr VANLAEYS Alison",
    src: Vanlaeys,
    poste: "Chef de projet R&D",
    bio: "Le Dr Vanlaeys a étudié les sciences biomédicales à l'Université de Caen et a obtenu son diplôme de Master en 2015. Elle a ensuite rejoint l’équipe du Pr Ouadid-Ahidouch au Laboratoire de physiologie moléculaire et cellulaire (LPCM-EA4667) au sein de l’université de Picardie Jules Verne (UPJV). Le fruit de 3 ans de recherche portant sur l’implication d’un canal ionique dans le cancer du pancréas et du sein a été présenté en décembre 2018 pour l’obtention du titre de Docteur. Elle est accédée en 2019 au poste de chercheuse post-doctoral au sein de l’équipe du Pr Mentaverri sur le projet CALARTOS au Laboratoire MP3CV (EA7517-UPJV). Ces recherches ont permis de mieux comprendre les mécanismes cellulaires à l’œuvre lors de la technique chirurgicale de Masquelet qui permet le comblement de longues pertes osseuses grâce à des dispositifs médicaux d’ostéosynthèse. Au cours de son parcours, elle a valorisé ces recherches grâce à la publication de 4 articles, 2 revues et 14 communications à des congrès nationaux, internationaux ou des évènements de vulgarisation scientifique. En juillet 2019, elle a accédé au poste de Chef de Projet au sein l’équipe R&D dirigée par M. Thierry Porée du Laboratoire OptimHal – ProtecSom. Elle travaille actuellement sur une chambre d’inhalation intelligente. L’objectif est que ce dispositif médical améliore l’observance des patients atteints de pathologies respiratoires chroniques."
  },
  {
    name: "HERVIEU Brenda",
    src: Hervieu,
    poste: "Chargée de Projet",
    bio: "Chimiste de formation et titulaire, depuis 2017, d’un master en chimie de l’environnement marin, je travaille dans l’entreprise OptimHal ProtecSom depuis 2019. Durant cette période, j’ai été formée aux différentes techniques permettant la caractérisation et le développement de chambre d’inhalation. Une majeure partie de mon travail, réalisé en partenariat avec le Dr Eckes, concerne les dispositifs médicaux en néonatologie.  \n" +
      "Depuis octobre 2020 je réalise une thèse CIFRE au sein de cette même entreprise en partenariat avec l’université de Caen. Mon sujet porte sur l’étude de la dynamique d’aérosols dans les dispositifs d’inhalation afin de concevoir des dispositifs plus précis."
  },
  {
    name: "Mr POREE Thierry",
    src: Poree,
    poste: "Directeur de Recherche / Labo OptimHal",
    bio: ""
  }
]

export { speakers }