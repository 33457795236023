import { navigate } from "gatsby-link"

const lobbymain = [
  {
    type: "scene",
    pitch: -10.7,
    yaw: -0.38,
    text: "Réception",
    sceneId: "desk"
  },
  {
    type: "scene",
    pitch: -9.2,
    yaw: -63,
    text: "Salle d'exposition",
    clickHandlerFunc: () => navigate("/congres/expo")
  },
  {
    type: "scene",
    pitch: -9.2,
    yaw: 63,
    text: "Salle d'exposition",
    clickHandlerFunc: () => navigate("/congres/expo")
  },
  {
    type: "scene",
    pitch: -3.6,
    yaw: -0.38,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: 1.5,
    yaw: -20,
    text: "Programme",
    clickHandlerFunc: () => navigate("/congres/program")
  },
  {
    type: "scene",
    pitch: 1.5,
    yaw: -7,
    text: "Orateurs",
    clickHandlerFunc: () => navigate("/congres/speakers")
  },
  {
    type: "scene",
    pitch: 1.5,
    yaw: 7,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "scene",
    pitch: 1.5,
    yaw: 20,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  }
]

const lobbydesk = [
  {
    type: "scene",
    pitch: -5,
    yaw: -10,
    text: "Entrée",
    sceneId: "main"
  },
  {
    type: "scene",
    pitch: -4.7,
    yaw: -47,
    text: "Salle d'exposition",
    clickHandlerFunc: () => navigate("/congres/expo")
  },
  {
    type: "scene",
    pitch: -4.7,
    yaw: 24.4,
    text: "Salle d'exposition",
    clickHandlerFunc: () => navigate("/congres/expo")
  },
  {
    type: "scene",
    pitch: -6.3,
    yaw: 163,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: 6.5,
    yaw: 125,
    text: "Programme",
    clickHandlerFunc: () => navigate("/congres/program")
  },
  {
    type: "scene",
    pitch: 8.1,
    yaw: 149,
    text: "Orateurs"
  },
  {
    type: "scene",
    pitch: 8.1,
    yaw: -179,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "scene",
    pitch: 7,
    yaw: -154,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "scene",
    pitch: 7,
    yaw: -154,
    text: "Video",
    clickHandlerFunc: () => navigate("/congres/posters")
  }
]
export { lobbymain, lobbydesk }