import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../../../../style/navbar.css"
import { Col, Form, Menu, Modal, Row } from "antd"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FileImageOutlined, LinkOutlined, MailOutlined, PhoneOutlined,
  PlayCircleOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"

const { SubMenu } = Menu
export default function Magpharm() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Magpharm"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/magpharm.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setupModal("presentation")}
            >
              Présentation
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FileImageOutlined />}>
                <a
                  href="https://ubiquity.business/saaic/magpharm/1.jpg"
                  target="_blank" download
                  style={{ fontSize: 18 }}>Bronchonet</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a
                  href="https://ubiquity.business/saaic/magpharm/3.jpg"
                  target="_blank" download
                  style={{ fontSize: 18 }}>Thymo</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a
                  href="https://ubiquity.business/saaic/magpharm/2.jpg"
                  target="_blank" download
                  style={{ fontSize: 18 }}>Solyne Magnesium</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a
                  href="https://ubiquity.business/saaic/magpharm/4.jpg"
                  target="_blank" download
                  style={{ fontSize: 18 }}>Solyne Vitamine D3</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Magpharm"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <p>
      Magpharm est un groupe pharmaceutique algérien, créé en 2003, qui produit
      et distribue des marques de renommée nationale et internationale, à savoir
      Phytopharm, Solyne, Mediflex, Santé Vie, Seco+, Novalac, Noreva, Alliance
      Pharmaceuticals, Polinelli, Phytencia, Avène et Klorane.
    </p>
    <p>
      Leader des produits de santé et de bien-être en Algérie, le groupe emploie
      près de 700 personnes dans les trois filiales qui le composent et est
      animé d’un véritable engagement citoyen, à travers des initiatives qui ont
      trait au développement durable et à l’insertion professionnelle des
      personnes en situation d’handicap
    </p>
  </div>

const contact =
  <Form>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://www.magpharm.com" target="_blank">www.magpharm.com</a>
    </Form.Item>
  </Form>