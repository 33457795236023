import React, { useState } from "react"
import Seo from "../../seo"
import { StaticImage } from "gatsby-plugin-image"
import Player from "./player"
import Slido from "./slido"
import Timeline from "./timeline"
import { Col, Grid, Row } from "antd"
import { useWindowSize } from "react-use"

const { useBreakpoint } = Grid
export default function AuditoriumIndex() {
  const { width } = useWindowSize()
  const screens = useBreakpoint()
  const [url, setUrl] = useState("")
  
  return (
    <div style={{ display: "grid", backgroundColor: "#05121E" }}>
      <Seo title={"Auditorium"} />
      {!screens.xs && (
        <StaticImage
          style={{
            gridArea: "1/1",
            maxHeight: "92vh"
          }}
          src={"../../../images/auditorium.jpg"}
          alt={"this is the background"}
          objectFit={"contain"}
        />
      )}
      <Row
        gutter={[10, 10]}
        style={{
          gridArea: "1/1",
          width: "100%",
          height: screens.xs ? "100vh" : "92vh",
          padding: 10
        }}
        justify="center"
        align={screens.xs ? "top" : "middle"}
      >
        <Col xs={{ span: 24, order: 2 }} md={{ span: 5, order: 1 }}
             style={{ height: screens.xs ? "" : "85vh" }}>
          <Timeline setUrl={setUrl} />
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 14, order: 2 }}
             style={{ height: screens.xs ? width / (16 / 9) : "85vh" }}
        >
          <Player url={url} />
        </Col>
        <Col xs={{ span: 24, order: 3 }} md={{ span: 5, order: 3 }}
             style={{ height: screens.xs ? 500 : "85vh" }}>
          <Slido />
        </Col>
      </Row>
    </div>
  )
}