import React from "react"
import Seo from "../../seo"
import Communications from "./communications"


export default function CommunicationsIndex() {
  return (
    <div >
      <Seo title={"Communications Orales"} />
      <Communications />
    </div>
  )
}