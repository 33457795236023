import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Form, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FileImageOutlined, FacebookFilled, MailOutlined, PhoneOutlined,
  PlayCircleOutlined,
  LinkedinFilled,
  InstagramOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"
const { SubMenu } = Menu

export default function Pharmalliance() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  return (
    <div style={{ display: "grid" }}>
      
      <Seo title={"Pharmalliance"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/pharmalliance.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item icon={<DesktopOutlined className={"icon2"} />} onClick={() => setupModal("presentation")}>
              Présentation
            </Menu.Item>
            <Menu.Item icon={<ContactsOutlined className={"icon2"} />} onClick={() => setupModal("contact")}>
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/pharmalliance/5.pdf" target="_blank" download style={{ fontSize: 18 }}>Produits</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/pharmalliance/6.pdf" target="_blank" download style={{ fontSize: 18 }}>Anti-infectives & Allergology</a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item icon={<PlayCircleOutlined />} onClick={() => setupModal("video")}>
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Pharmalliance"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <p>
      Pharmalliance est née d’une forte initiative, portée par une volonté de répondre
      aux besoins du patient algérien d’un médicament générique de marque, de
      qualité et accessible. Dès le départ, l’exigence est posée comme ADN de
      Pharmalliance : celle d’un produit de qualité.
    </p>
    <p>
      Au fil du temps, basée sur une stratégie et un développement harmonieux,
      l’idée s’est transformée, presque 30 ans aprés, une aventure humaine et
      professionnelle d’une richesse profonde et multiple, toujours orientée vers le
      patient algérien. Aujourd’hui, elle est portée par près de 1000 collaborateurs,
      basés en Algérie, formés en Algérie, talents attirés et retenus à Pharmalliance,
      car mus par la même passion d’offrir au patient un produit de qualité au
      moindre coût, et convaincus de leur mission de participer à développer un
      environnement de santé humanisé, par leur abnégation et leur dévouement.
      Produit de l’école algérienne, Pharmalliance a avancé avec l’ambition de
      proposer au patient et au praticien algérien un générique de marque, un brand
      aux normes internationales.
    </p>
    <p>
      Cela s’est concrétisé par la production de plusieurs centaines de médicaments
      devenus depuis, pour certains, des produits de référence, comme le proton
      (…) Se démarquant, Pharmalliance a proposé un médicament générique de
      qualité, avec une identité complète, de sa formule jusqu’à son identité visuelle,
      et s’est ainsi placée parmi les premiers laboratoires en Algérie.
    </p>
    <p>
      L’aventure ne fait que commencer car Pharmalliance, avec une flexibilité et
      une agilité acquises sur le terrain, s’est adaptée aux nouveaux besoins des
      patients à chaque fois, et a réussi à proposer un produit dans des délais très
      courts. Elle s’est adaptée aux changements juridiques par l’anticipation et la
      collaboration avec les autorités de santé, enregistrant parfois son générique de
      marque, avant le produit princeps, comme le Roxid Elle a surtout accompagn
      les développements de la science dans son domaine et a créé son propre
      département de recherche et développement.
    </p>
    <p>
      Si l’importation a constitué un premier pas nécessaire, le développement d’un
      médicament générique de marque, avec une identité complète, avec essais en
      laboratoire et sur l’homme était dès le départ, l’enjeu et l’objectif à atteindre,
      garantissant une indépendance certaine dans la fabrication. Aujourd’hui,
      Pharmalliance veut proposer une solution médicamenteuse encore plus globale,
      plus complète et en phase avec les dernières pratiques de santé. Pour cela,
      elle lance une large gamme de compléments alimentaires, pour accompagner
      son patient à tous les niveaux de préservation de sa santé, de la prévention, au
      traitement, au conseil. Et forte de sa riche expérience, Pharmalliance compte
      bâtir de nouveaux ponts vers le futur, en continuant à développer un produit
      innovant, par le développement de sa recherche et de sa ressource humaine
      afin de s’adapter aux besoins de ses patients, en les anticipant même et en y
      répondant rapidement et avec la garantie de la qualité.
    </p>
  </div>

const video=
  <ReactPlayer
    url={"https://ubiquity.business/saaic/pharmalliance/v1.mp4"}
    controls
    width={1024}
    height={576}
  />

const contact=
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>023 33 64 87</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:communication@pharmalliance.dz">communication@pharmalliance.dz</a>
    </Form.Item>
    <Form.Item label={<FacebookFilled />}>
      <a href="https://www.facebook.com/pharmallianceEURL" target="_blank">Pharmalliance</a>
    </Form.Item>
    <Form.Item label={<InstagramOutlined />}>
      <a href="https://www.instagram.com/pharmalliancedz" target="_blank">Pharmalliance</a>
    </Form.Item>
    <Form.Item label={<LinkedinFilled />}>
      <a href="https://www.linkedin.com/company/pharmalliance-algerie" target="_blank">Pharmalliance</a>
    </Form.Item>
  </Form>