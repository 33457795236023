import React, { useState } from "react"
import { Card, Col, Modal, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import Iframe from "react-iframe"
import { posters } from "../../../utils/posters"

const { Meta } = Card
const { Title, Text } = Typography
export default function Posters() {
  const [modalInfos, setModalInfos] = useState({
    visible: false,
    title: ""
  })
  return (
    <div style={{ padding: 10, backgroundColor: "#F4F4F4" }}>
      <Row gutter={[10, 10]}>
        {posters.map((poster, key) => (
          <Col xs={{ span: 24 }} md={{ span: 4 }} key={key}>
            <Card
              onClick={() => setModalInfos({
                ...modalInfos,
                visible: true,
                url: poster.url,
                title: poster.title
              })}
              hoverable
              style={{ height: "100%" }}
              cover={
                <StaticImage
                  src={"../../../images/posterthumbnail.jpg"}
                  alt={"a player image"}
                  objectFit={"contain"}
                />
              }
            >
              <Meta
                description={
                  <>
                    <Title level={5}>
                      {poster.title}
                    </Title>
                    <Text type="secondary">
                      {poster.authors}
                    </Text>
                  </>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        width={"auto"}
        onCancel={() => setModalInfos({ ...modalInfos, visible: false })}
        title={modalInfos.title}
        visible={modalInfos.visible}
        centered
        footer={null}
      >
        <Iframe
          url={modalInfos.url}
          width={1024}
          height={576}
          allowFullScreen
        />
      </Modal>
    </div>
  )
}