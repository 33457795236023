import React from "react"
import ReactPlayer from "react-player"

export default function Player({ url }) {
  
  return (
      <ReactPlayer
        height="100%"
        width="100%"
        url={url}
        controls
      />
  )
}