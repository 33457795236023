import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Form, Menu, Modal, Row } from "antd"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FacebookFilled,
  FilePdfOutlined,
  InstagramOutlined,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined
} from "@ant-design/icons"
import "../../../../../style/navbar.css"

const { SubMenu } = Menu
export default function Astrazeneca() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Astrazeneca"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/astrazeneca.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item icon={<DesktopOutlined className={"icon2"} />}
                       onClick={() => setupModal("presentation")}>
              Présentation
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/astra/1.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Symbicort 1</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/astra/4.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Symbicort 2</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/astra/2.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Turbuhaler</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
              <a href="https://ubiquity.business/saaic/astra/3.pdf"
                 target="_blank" download
                 style={{ fontSize: 18 }}>Pulmicort</a>
            </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Astrazeneca"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}


const presentation=
  <div>
    <p>
      AstraZeneca est un groupe biopharmaceutique international guidé par
      la
      science, axé sur la recherche, le développement, la production et la
      commercialisation de médicaments de prescription
    </p>
    <p>
      "Ensemble, nous sommes convaincus qu’en étant à la pointe de la
      science,
      nous pouvons transformer la vie des patients." Pascal Soriot Chief
      Executive Officer
    </p>
    <h4>Notre ambition et nos valeurs</h4>
    <p>
      Notre ambition et nos valeurs expliquent notre raison d’être, nos
      espoirs,
      les comportements que nous valorisons, la façon dont nous allons
      atteindre
      nos objectifs, et la promesse de notre marque pour les différentes
      parties
      prenantes
    </p>
    <p>
      Nous repoussons les frontières pour délivrer des médicaments qui
      changent
      la vie des patients
    </p>
  </div>

const contact=
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 21 98 20 00</span>
    </Form.Item>
  </Form>
