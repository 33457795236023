const day1 = [
  
  //Ateliers
  {
    title: "Atelier 1",
    description: "Conduite à tenir devant une toux chronique de l’enfant",
    url: "https://player.vimeo.com/video/567077282",
    speaker: "A. BOUFERSAOUI",
    date: "08H00-08H30"
  },
  {
    title: "Atelier 2",
    description: "Allergies aux protéines du lait de vache",
    url: "https://player.vimeo.com/video/567080213",
    speaker: "W. DRALI, F. LAMRANI",
    date: "08H30-09H00"
  },
  {
    title: "Atelier 3",
    description: "Capillaroscopie : cliniques et aspects pratiques",
    url: "https://player.vimeo.com/video/567080240",
    speaker: "S. LARBANI, MH BOUCHOUAREB",
    date: "09H00-09H30"
  },
  {
    title: "Atelier 4",
    description: "CAT devant un prurit chronique",
    url: "https://player.vimeo.com/video/567080255",
    speaker: "C. OUARAB, F. ZERKAOUI",
    date: "09H30-10H00"
  },
  {
    title: "Atelier 5",
    description: "Cas cliniques de lecture TDM en pathologies thoraciques adultes et enfants",
    url: "https://player.vimeo.com/video/567080276",
    speaker: "R. BOUGHRAROU, M. LEMDANI",
    date: "10H00-10H30"
  },
  //Session 1
  {
    title: "Conférence 1",
    description: "Les allergies aux poissons",
    url: "https://player.vimeo.com/video/566513914",
    speaker: "P. DA MATA ( Lisbonne )",
    date: "11H00-11H20"
  },
  {
    title: "Conférence 2",
    description: "Intérêt des microcapteurs connectés pour la population et les patients atteints d’une maladie respiratoire chronique",
    url: "https://player.vimeo.com/video/567082288",
    speaker: "D. CHARPIN ( Marseille )",
    date: "11H20-11H40"
  },
  {
    title: "Conférence 3",
    description: "Efficacité et coût de la prise en charge de la pollution intérieure",
    url: "https://player.vimeo.com/video/567080310",
    speaker: "C. MARCOT ( Strasbourg )",
    date: "11H40-12H00"
  },
  {
    title: "Symposium OPTIMHAL",
    description: "Chambre d’inhalation : nouvelles avancées en néonatologie\n" +
      "et en pediatrie",
    url: "https://player.vimeo.com/video/567260651",
    speaker: "A. VANLAEYS, B.HERVIEU, T.POREE ( Paris )",
    date: "12H00-12H45"
  },
  {
    title: "Symposium BEKER",
    description: "La phytothérapie pour la prise en charge des affections respiratoire",
    url: "https://player.vimeo.com/video/567260627",
    speaker: "S.HELLAL (ALGER)",
    date: "12H45-13H30"
  },
  //session 2
  {
    title: "Conférence 4",
    description: "Mécanismes immunologiques de l’inflammation de type 2",
    url: "https://player.vimeo.com/video/567077305",
    speaker: "F. BENABADJI ( Alger )",
    date: "13H30-13H50"
  },
  {
    title: "Conférence 5",
    description: "L’asthme sévère chez l’enfant",
    url: "https://player.vimeo.com/video/567084131",
    speaker: "A. BOUFERSAOUI ( Alger )",
    date: "13H50-14H10"
  },
  {
    title: "Conférence 6",
    description: "L’asthme sévère chez l’adulte en Afrique",
    url: "https://player.vimeo.com/video/567084105",
    speaker: "M.OUEDRAGO ( Ouagadougou,Burkina Faso)",
    date: "14H10-14H30"
  },
  {
    title: "Conférence 7",
    description: "Conférence d’actualité : dissémination globale des clones gram négatifs multirésistants",
    url: "https://player.vimeo.com/video/567405391",
    speaker: "P.GLASER (Paris)",
    date: "14H30-15H15"
  },
  {
    title: "Symposium PHARMALLIANCE",
    description: "Etat des résistances aux antibiotiques en Algérie et dans le\n" +
      "monde et place actuelle des céphalosporines dans la prise en\n" +
      "charge des infections respiratoires de l’adulte et de l’enfant.",
    url: "https://player.vimeo.com/video/567260406",
    speaker: "D.YALA ( Alger )",
    date: "15H15-16H00"
  },
  //Session 3
  {
    title: "Conférence 8",
    description: "Relations nez-bronche",
    url: "https://player.vimeo.com/video/567161063",
    speaker: "S. BENYAHIA. ( Alger )",
    date: "16H00-16H20"
  },
  {
    title: "Conférence 9",
    description: "Mécanismes d’action des biothérapies dans la polypose naso-sinusienne",
    url: "https://player.vimeo.com/video/567549511",
    speaker: "N. YAHI ( Alger )",
    date: "16H20-16H40"
  },
  {
    title: "Conférence 10",
    description: "Polypose nasale : chirurgie ou biothérapie",
    url: "https://player.vimeo.com/video/567085676",
    speaker: "M. BENZAMIT ( Sétif )",
    date: "16H40-17H00"
  },
  //Session 4
  {
    title: "Conférence 11",
    description: "L’allergie oculaire court toujours à l’ère du covid-19",
    url: "https://player.vimeo.com/video/567085701",
    speaker: "F. KARAOUAT ( Alger )",
    date: "17H00-17H20"
  },
  {
    title: "Conférence 12",
    description: "Allergies oculaires chez l’enfant",
    url: "https://player.vimeo.com/video/567085719",
    speaker: "W. CHERABLI ( Alger)",
    date: "17H20-17H40"
  },
  {
    title: "Conférence 13",
    description: "Oeil et environnement",
    url: "https://player.vimeo.com/video/567085783",
    speaker: "D. SOUTTOU ( Alger)",
    date: "17H40-18H00"
  },
  {
    title: "Symposium EL KENDI",
    description: "Asthme de l’adulte : update Gina 2021",
    url: "https://player.vimeo.com/video/567260676",
    speaker: "R. ABDELLAZIZ ( Alger)",
    date: "18H00-18H45"
  },
  //Session 5
  {
    title: "Conférence 14",
    description: "Update Gina 2021",
    url: "https://player.vimeo.com/video/567156349",
    speaker: "H. DOUAGUI ( Alger )",
    date: "18H45-19H05"
  },
  {
    title: "Conférence 15",
    description: "When glaucoma meets OSAS",
    url: "https://player.vimeo.com/video/567085798",
    speaker: "W.CHERABLI ( Alger)",
    date: "19H05-19H25"
  },
  {
    title: "Conférence 16",
    description: "Imaging of lung cancer: implications on statement and management",
    url: "https://player.vimeo.com/video/567085827",
    speaker: "R. BOUGHRAROU ( Alger )",
    date: "19H25-19H45"
  },
  {
    title: "Conférence 17",
    description: "Some thoughts on the Covid 19 viral pandemic in Algeria",
    url: "https://player.vimeo.com/video/567085848",
    speaker: "A. LAMARA MAHAMED ( Alger )",
    date: "19H45-20H05"
  }
]

const day2 = [
  //Ateliers
  {
    title: "Atelier 6",
    description: "Allergies aux bétalactamines : conduite à tenir",
    url: "https://player.vimeo.com/video/567087552",
    speaker: "R. ABDELLAZIZ, L. SEKFALI",
    date: "07H30-08H00"
  },
  {
    title: "Atelier 7",
    description: "Les allergies aux cosmétiques",
    url: "https://player.vimeo.com/video/567087568",
    speaker: "A. BELKACEM, D. OUALI, N. MAZOUZ",
    date: "08H00-08H30"
  },
  {
    title: "Atelier 8",
    description: "Quand demander un bilan immunologique",
    url: "https://player.vimeo.com/video/567087595",
    speaker: "N. LYAZIDI, N. DAHMAN",
    date: "08H30-09H00"
  },
  {
    title: "Atelier 9",
    description: "Atelier du groupe ophtalmo-allergologie (GOA)",
    url: "https://player.vimeo.com/video/567087613",
    speaker: "F.KARAOUAT, W. CHERABLI, D.SOUTOU,\n" +
      "N. MAZOUZ, M. OUALI, H. DOUAGUI",
    date: "09H00-09H30"
  },
  {
    title: "Symposium BAUSCH & LOMB",
    description: "L’allergie oculaire à l’ordre du jour",
    url: "https://player.vimeo.com/video/567944657",
    speaker: "F.KARAOUAT (Alger)",
    date: "09h30-10h00"
  },
  //Session 6
  {
    title: "Conférence 18",
    description: "Pneumopathies d’hypersensibilité chez l’enfant",
    url: "https://player.vimeo.com/video/567087627",
    speaker: "A. DAHMANE (Alger)",
    date: "10H00-10H20"
  },
  {
    title: "Conférence 19",
    description: "Les allergies aux vaccins",
    url: "https://player.vimeo.com/video/567087649",
    speaker: "KN. BENHALLA (Alger)",
    date: "10H20-10H40"
  },
  {
    title: "Conférence 20",
    description: "SAOS chez l’enfant : diagnostic et prise en charge",
    url: "https://player.vimeo.com/video/567087673",
    speaker: "O. DRALI (Alger)",
    date: "10H40-11H00"
  },
  //Session 7
  {
    title: "Conférence 21",
    description: "Induction de tolérance à l’allergie aux protéines du lait de\n" +
      "vache (PLV)",
    url: "https://player.vimeo.com/video/567089313",
    speaker: "A. DAHMANE (Alger)",
    date: "11H00-11H20"
  },
  {
    title: "Conférence 22",
    description: "Allergies à l’oeuf",
    url: "https://player.vimeo.com/video/567089334",
    speaker: "A. BOUFERSAOUI (Alger)",
    date: "11H20-11H40"
  },
  {
    title: "Conférence 23",
    description: "Profil des allergies alimentaires chez les enfants de moins de 5 ans dans une commune d’Alger",
    url: "https://player.vimeo.com/video/567089356",
    speaker: "L. SEKFALI (Alger)",
    date: "11H40-12H00"
  },
  //Session 8
  {
    title: "Conférence 24",
    description: "Mécanismes immuno-physiopathologiques",
    url: "https://player.vimeo.com/video/567252955",
    speaker: "F.BENABADJI (Alger)",
    date: "12H00-12H20"
  },
  {
    title: "Conférence 25",
    description: "Le diagnostic du choc anaphylactique",
    url: "https://player.vimeo.com/video/567089379",
    speaker: "Y. TOLOBA (Bamako, Mali)",
    date: "12H20-12H40"
  },
  {
    title: "Conférence 26",
    description: "Le traitement du choc anaphylactique",
    url: "https://player.vimeo.com/video/567089402",
    speaker: "R. AOUAMEUR (Alger)",
    date: "12H40-13H00"
  },
  {
    title: "Symposium MAGPHARM",
    description: "Covid 19 et antioxydants ( Vitamin D, Zinc, Magnesium )",
    url: "https://player.vimeo.com/video/567260464",
    speaker: "A. LAMARA MAHAMED (Alger)",
    date: "13H00-13H45"
  },
  //Session 9
  {
    title: "Conférence 27",
    description: "Manifestations hématologiques au cours des maladies systémiques",
    url: "https://player.vimeo.com/video/567089413",
    speaker: "C. OUARAB (Alger)",
    date: "13H45-14H05"
  },
  {
    title: "Conférence 28",
    description: "Uvéites en médecine interne : diagnostic étiologique et principes du traitement",
    url: "https://player.vimeo.com/video/567089434",
    speaker: "S. BOUMEDINE (Alger)",
    date: "14H05-14H25"
  },
  {
    title: "Conférence 29",
    description: "Déficits immunitaires de l’adulte : aspects cliniques,biologiques et évolutifs",
    url: "https://player.vimeo.com/video/567078711",
    speaker: "M. BERRAH (Alger)",
    date: "14H25-14H45"
  },
  {
    title: "Symposium GSK",
    description: "Approche thérapeutique de l’asthme léger de l’adulte",
    url: "https://player.vimeo.com/video/566515199",
    speaker: "H.HAOUICHET (Alger)",
    date: "14H45-15H15"
  },
  //Session 10
  {
    title: "Conférence 30",
    description: "Dermatite atopique chez l’enfant",
    url: "https://player.vimeo.com/video/567078688",
    speaker: "K. AIT IDIR ( Alger )",
    date: "15H15-15H35"
  },
  {
    title: "Conférence 31",
    description: "Manifestations cutanées des toxidermies",
    url: "https://player.vimeo.com/video/567078661",
    speaker: "A. BELKACEM (Tizi-Ouzou)",
    date: "15H35-15H55"
  },
  {
    title: "Conférence 32",
    description: "Données actuelles sur la prise en charge de l’urticaire chronique spontané",
    url: "https://player.vimeo.com/video/567078629",
    speaker: "G. MALKI (Béjaia)",
    date: "15H55-16H15"
  },
  {
    title: "Symposium SANOFI PASTEUR",
    description: "Coqueluche : les enjeux de la vaccination",
    url: "https://player.vimeo.com/video/567260446",
    speaker: "K.N.BENHALLA (Alger)",
    date: "16H15-17H00"
  },
  {
    title: "Symposium INPHAMEDIS",
    description: "Rhinite allergique, COVID 19 et grippe saisonnière : comment\n" +
      "les diagnostiquer et comment les traiter.",
    url: "https://player.vimeo.com/video/567260436",
    speaker: "G. MALKI (Béjaia)",
    date: "17H00-17H45"
  },
  {
    title: "Symposium SANOFI GENZYME",
    description: "Données actuelles sur l’asthme sévère",
    url: "https://player.vimeo.com/video/567260420",
    speaker: "R.ABDELAZIZ (Alger)",
    date: "17H45-18H15"
  },
  {
    title: "Symposium NOVARTIS",
    description: "La place de l’omalizumab (xolair) dans la prise en charge de l’asthme sévère allergique.",
    url: "https://player.vimeo.com/video/567260664",
    speaker: "O.CHABATI (Alger)",
    date: "18H15-19H00"
  },
  //Session 11
  {
    title: "Conférence 33",
    description: "Le post Covid 19 : état des connaissances actuelles",
    url: "https://player.vimeo.com/video/567078605",
    speaker: "N. LIANI (Alger)",
    date: "19H00-19H20"
  },
  {
    title: "Conférence 34",
    description: "Prise en charge du personnel hospitalier dans le contexte de la pandémie du Covid 19 : l’expérience d’un service\n" +
      "de médecine du travail d’Alger",
    url: "https://player.vimeo.com/video/567078583",
    speaker: "H. BENMESSAOUD (Alger)",
    date: "19H20-19H40"
  },
  {
    title: "Conférence 35",
    description: "Recommandations de sécurité sanitaire pour la reprise et la\n" +
      "continuité du travail en période d’épidémie du Covid 19",
    url: "https://player.vimeo.com/video/567091514",
    speaker: "N. AKIF ( Alger )",
    date: "19H40-20H00"
  }
]
const day3 = [
  //Session 12
  {
    title: "Conférence 36",
    description: "Mécanismes physiopathologiques de l’Angio-OEdème\n" +
      "Héréditaire (AOH)",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "K. DJENOUHAT (Alger)",
    date: "09H00-09H20"
  },
  {
    title: "Conférence 37",
    description: "Démarches diagnostiques de l’AOH",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "W. DRALI (Alger)",
    date: "09H20-09H40"
  },
  {
    title: "Conférence 38",
    description: "La prise en charge de l’AOH chez l’enfant",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "H. MESSAOUDI (Alger)",
    date: "09H40-10H00"
  },
  {
    title: "Symposium TAKEDA",
    description: "Le traitement de l’AOH de l’adulte",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "L. BOUILLET (Grenoble)",
    date: "10H30-11H00"
  },
  //Session 13
  {
    title: "Conférence 39",
    description: "Covid 19 : quelles perspectives pour une sortie de crise",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "A. FONTANET (Paris)",
    date: "11H00-11H20"
  },
  {
    title: "Conférence 40",
    description: "Microbiote et Covid 19",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "A. HAMZAOUI (Tunis)",
    date: "11H20-11H40"
  },
  {
    title: "Conférence 41",
    description: "Variants du Covid 19 : difficultés diagnostiques et conséquences sur la vaccination",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "F. BENABADJI (Alger)",
    date: "11H40-12H00"
  },
  {
    title: "Conférence 42",
    description: "Fibrose post Covid 19 : que retenir de cette pandémie",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "R. BOUGHRAROU (Alger)",
    date: "12H00-12H20"
  },
  {
    title: "",
    description: "Discussion",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "",
    date: "12H20-12H55"
  },
  {
    title: "",
    description: "Séances de Questions et Réponses des sessions scientifiques",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "",
    date: "13H15-18H30"
  },
  {
    title: "",
    description: "Clôture du Congrès.",
    url: "https://player.vimeo.com/video/566964895",
    speaker: "",
    date: "18H30-18H45"
  },
]

export { day1, day2, day3 }