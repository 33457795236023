import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import "../../../../../style/navbar.css"
import { Col, Menu, Modal, Row } from "antd"
import {
  DesktopOutlined,
  DownloadOutlined,
  FilePdfOutlined
} from "@ant-design/icons"
import "./accentis.css"

const { SubMenu } = Menu
export default function Accentis() {
  const [modalVisible, setModalVisible] = useState(false)
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Accentis"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/accentis.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              key="0"
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setModalVisible(true)}
            >
              Présentation
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<DownloadOutlined className={"icon2"} />}
              title="Téléchargements"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/accentis/1.pdf"
                   download style={{ fontSize: 18 }} target="_blank">Budek Plus
                  1</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/accentis/2.pdf"
                   download style={{ fontSize: 18 }} target="_blank">Budek Plus
                  2</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/accentis/3.pdf"
                   download style={{ fontSize: 18 }} target="_blank">Budek Plus
                  3</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/accentis/rcp.pdf"
                   download style={{ fontSize: 18 }}>RCP Budek Plus</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Présenation Accentis"}
        visible={modalVisible}
        okButtonProps={{ title: "Fermer" }}
        onOk={() => setModalVisible(false)}
        cancelButtonProps={undefined}
        width="auto"
        centered
      >
        <div style={{ overflow: "scroll" }}>
          <div class="dclr"></div>
          <p class="p0 ft0">Exeltis est la filiale pharmaceutique du groupe
            INSUD PHARMA</p>
          <p class="p1 ft0">Le groupe INSUD PHARMA au revenu de 1,4 Milliard de
            dollars en 2018.</p>
          <p class="p2 ft0">Fondé en 1977 par le couple argentin : la
            biochimiste Silvia Gold et le médecin Hugo Sigman. Représenté dans
            50 Pays, son siège social se trouve à Madrid (Espagne).</p>
          <p class="p3 ft0"><span class="ft1"> </span>Exeltis fusionne les
            connaissances, l'expérience et l'esprit d'innovation du groupe pour
            développer, produire et commercialiser des médicaments et des
            dispositifs médicaux.</p>
          <p class="p4 ft0">Les laboratoires EXELTIS ont un portefeuille produit
            de plus de 300, Le laboratoire est présent dans</p>
          <p class="p5 ft0">40 filiales à travers le monde avec 4 000 employés,
            avec les spécialités :</p>
          <p class="p6 ft0"><span class="ft0">-</span><span class="ft2">Pneumologie et Système Respiratoire </span>:
            Large gamme de médicaments par voie orale et par inhalation et
            investir dans le développement et l’acquisition d’options de
            traitement innovantes.</p>
          <p class="p7 ft0"><span class="ft0">-</span><span class="ft2">Santé des femmes </span>:
            Large portefeuille produit en gynécologie afin de répondre aux
            besoins des femmes à toutes les étapes de leurs vies.</p>
          <p class="p8 ft0"><span class="ft0">-</span><span
            class="ft2">Dermatologie </span>: Développement et commercialisation
            de nouvelles thérapies innovantes en dermatologie.</p>
          <p class="p9 ft0"><span class="ft0">-</span><span
            class="ft2">SNC </span>: Amélioration de la santé de nos patients
            souffrant de troubles neurologiques ou maladies psychiatriques.</p>
          <p class="p10 ft3">Notre mission :</p>
          <p class="p11 ft4">Faire la différence dans la vie de tous les jours
            de millions de personnes, familles et personnel de santé en donnant
            accès à des solutions thérapeutiques efficaces de premier ordre.</p>
          <p class="p12 ft0">Améliorer les soins de santé à l'avenir, en menant
            des recherches cliniques et en développant des traitements nouveaux
            et innovants ; et étendre nos activités en étant présents aux quatre
            coins du monde.</p>
          <p class="p13 ft3">Notre vision <span class="ft0">:</span></p>
          <p class="p14 ft0">Notre vision est fondée sur les valeurs
            d'amélioration constante, d'excellence et d'innovation, dans le but
            de répondre aux besoins médicaux non satisfaits de la société.</p>
        </div>
      </Modal>
    </div>
  )
}