import React from "react"
import { Card, Col, Row, Tabs } from "antd"
import Timeline from "./timeline"
import { day1, day2, day3 } from "../../../utils/program"
import Seo from "../../seo"
import { StaticImage } from "gatsby-plugin-image"

const { TabPane } = Tabs
export default function ProgramIndex() {
  return (
    <div style={{ display: "grid" }}>
      <Seo title="Programme" />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          filter: "blur(8px)"
        }}
        l
        src={
          "../../../images/background-saaic.jpg"
        }
        formats={["auto", "webp", "avif"]}
      />
      <Row justify={"center"} style={{ gridArea: "1/1" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 14 }}
        >
          <Card
            style={{
              backgroundColor: "rgba(255,255,255,0.5)",
              borderStyle: "none",
              maxHeight: "92vh"
            }}
          >
            <Tabs
              defaultActiveKey="1"
              centered
              size="large"
              type="card"
              style={{
                maxHeight: "86vh"
              }}
            >
              <TabPane
                tab="Vendredi 25 Juin"
                key="1"
                style={{
                  overflow: "scroll",
                  maxHeight: "100vh"
                }}
              >
                <Timeline elements={day1} />
                <br/>
                <br/>
                <br/>
              </TabPane>
              <TabPane
                tab="Samedi 26 Juin"
                key="2"
                style={{
                  overflow: "scroll",
                  maxHeight: "100vh"
                }}
              >
                <Timeline elements={day2} />
                <br/>
                <br/>
                <br/>
              </TabPane>
              <TabPane
                tab="Dimanche 27 Juin"
                key="3"
                style={{
                  overflow: "scroll",
                  maxHeight: "100vh"
                }}
              >
                <Timeline elements={day3} />
                <br/>
                <br/>
                <br/>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  )
}