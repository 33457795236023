import React from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Menu, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  EyeOutlined,
  FileImageOutlined
} from "@ant-design/icons"
const { SubMenu } = Menu
export default function Novartis() {
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Novartis"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/novartis.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/novartis/1.png" download target="_blank" style={{ fontSize: 18 }}>Xolair 1</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/novartis/2.png" download target="_blank" style={{ fontSize: 18 }}>Xolair 2</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/novartis/3.png" download target="_blank" style={{ fontSize: 18 }}>Xolair 3</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/novartis/4.png" download target="_blank" style={{ fontSize: 18 }}>Xolair 4</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
    </div>
  )
}