function getSelectedMenu(path) {
  if (path === "/congres/" || path === "/congres") {
    return "1"
  }
  if (path.includes("expo")) {
    return "3"
  }
  if (path.includes("auditorium")) {
    return "4"
  }
  if (path.includes("posters")) {
    return "5"
  }
  if (path.includes("program")) {
    return "6"
  }
  if (path.includes("speakers")) {
    return "7"
  }
  if (path.includes("communications")) {
    return "8"
  }
}

export { getSelectedMenu }