const communications = [
  {
    title: "Cas devant une pneumopathie infiltrante diffuse de l’enfant",
    speaker: "A. Bouferssaoui",
    url: "https://vimeo.com/566551538"
  },
  {
    title: "Anti-IGE au cours de l'asthme severe experience du serevice de pneumologie de l'HCA",
    speaker: "S. Aissani",
    url: "https://vimeo.com/566553381"
  },
  {
    title: "Syndrome polyglandulaire chez l'enfant",
    speaker: "N. Bouferoua",
    url: "https://vimeo.com/566554829"
  },
  {
    title: "Les hépatites auto-immunes chez l’enfant",
    speaker: "N. Boukhedouma",
    url: "https://vimeo.com/566556579"
  },
  {
    title: "Asthme sévère & SAHOS",
    speaker: "F. Chaouki",
    url: "https://vimeo.com/566560587"
  },
  {
    title: "Asthme sévère & Comorbidités",
    speaker: "F. Chaouki ",
    url: "https://vimeo.com/566602922"
  },
  {
    title: "Symptome atypique de la sarcoïdose ostéo-articulaires",
    speaker: "F. Zerkaoui",
    url: "https://vimeo.com/566562462"
  },
  {
    title: "Syndrome de Gougerot Sjörgen secondaire",
    speaker: "F. Zerkaoui",
    url: "https://vimeo.com/566563707"
  },
  {
    title: "Prévalence et facteurs prédictifs d’asthme dans la rhinite allergique",
    speaker: "A. Hameg",
    url: "https://vimeo.com/566565526"
  },
  {
    title: "Particularités de la BPCO chez la femme",
    speaker: "N. Hammache",
    url: "https://vimeo.com/566567369"
  },
  {
    title: "Impact des normes spirométriques sur le diagnostique de la BPCO",
    speaker: "N. Hammache",
    url: "https://vimeo.com/566571696"
  },
  {
    title: "Asthme de l’adulte et Covid-19 : expérience du service de pneumologie du CHU de Tizi-Ouzou",
    speaker: "L. Ihadjadene ",
    url: "https://vimeo.com/566575762"
  },
  {
    title: "Retinal central artery occlusion experience of the internal medicine department",
    speaker: "N. Khabach ",
    url: "https://vimeo.com/566580540"
  },
  {
    title: "Treatment of hypertension in patients with asthma",
    speaker: "S. Larbani ",
    url: "https://vimeo.com/566584621"
  },
  {
    title: "Ataxies télangiectasies",
    speaker: "A. Mohand-Oussaid",
    url: "https://vimeo.com/566588103"
  },
  {
    title: "Syndrome des anti-synthétases et immunosuppresseurs : expérience du service",
    speaker: "Z. Mokrane ",
    url: "https://vimeo.com/566589504"
  },
  {
    title: "Maladies auto-immunes et inflammatoires et infection COVID 19 : à propos d’une série de 15 cas",
    speaker: "N. Dahman",
    url: "https://vimeo.com/566592011"
  },
  {
    title: "Particularités des PID au cours des dermatomyosites à MDA 5  : A propos de 4 cas",
    speaker: "N. Dahman",
    url: "https://vimeo.com/566593655"
  },
  {
    title: "Les Thromboses insolites : à propos d’une série de 30 cas",
    speaker: "N. Lyazidi",
    url: "https://vimeo.com/566599464"
  },
  {
    title: "Nurolupus à propos d’une série de 16 cas",
    speaker: "N. Lyazidi",
    url: "https://vimeo.com/566543569"
  },
  {
    title: "Syndrome pneumo-rénal : A propos d’une série de 8 cas",
    speaker: "N. Lyazidi",
    url: "https://vimeo.com/566544638"
  },
  {
    title: "Embolie pulmonaire et COVID 19, profil clinique et évolutif : A propos de 3 cas ",
    speaker: "C. Ouarab",
    url: "https://vimeo.com/566545433"
  },
  {
    title: "Covid 19 chez l’enfant : expérience mono-centrique",
    speaker: "W. Drali",
    url: "https://vimeo.com/566546377"
  },
  {
    title: "Syndrome d’hyper IgE chez l’enfant: Mise au Point",
    speaker: "W. Drali",
    url: "https://vimeo.com/566548312"
  },
  {
    title: "L’atteinte mediastino-pulmonaire de la maladie associées aux IgG4",
    speaker: "S. Zitouni ",
    url: "https://vimeo.com/566550564"
  }
]

export { communications }