import React from "react"

export default function Description({ bio, poste }) {
  return (
    <p>
      {poste}
      <br />
      {bio}
    </p>
  )
}