import React, { useEffect } from "react"
import { Router } from "@reach/router"
import CongresLayout from "../components/congres/layout/desktop"
import HomeIndex from "../components/congres/home"
import AuditoriumIndex from "../components/congres/auditorium"
import PostersIndex from "../components/congres/posters"
import ProgramIndex from "../components/congres/program"
import SpeakersIndex from "../components/congres/speakers"
import ExpoIndex from "../components/congres/expo"
import { navigate } from "gatsby-link"
import Pierrefabre from "../components/congres/expo/stands/gold/pierrefabre"
import Novartis from "../components/congres/expo/stands/gold/novartis"
import Optimhal from "../components/congres/expo/stands/gold/optimhal"
import Gsk from "../components/congres/expo/stands/platinium/gsk"
import Sanofi from "../components/congres/expo/stands/platinium/sanofi"
import Inphamedis from "../components/congres/expo/stands/platinium/inphamedis"
import BauschLomb from "../components/congres/expo/stands/silver/bauschLomb"
import Beker from "../components/congres/expo/stands/silver/beker"
import Pharmalliance
  from "../components/congres/expo/stands/silver/pharmalliance"
import Astrazeneca from "../components/congres/expo/stands/standard/astrazeneca"
import Biopharm from "../components/congres/expo/stands/standard/biopharm"
import Magpharm from "../components/congres/expo/stands/standard/magpharm"
import CommunicationsIndex from "../components/congres/communications"
import Accentis from "../components/congres/expo/stands/gold/accentis"
import Elkendi from "../components/congres/expo/stands/silver/elkendi"
import Chiesi from "../components/congres/expo/stands/standard/chiesi"
import Takeda from "../components/congres/expo/stands/standard/takeda"
import Genericlab from "../components/congres/expo/stands/platinium/genericlab"
import Fraterrazes from "../components/congres/expo/stands/standard/fraterrazes"

const Wrapper = ({ children }) => {
  return children
}
export default function Congres() {
  
  useEffect(() => {
    if (!localStorage.getItem("userId")) {
      navigate("/")
    }
  }, [])
  
  return (
    <div style={{ height: "100%" }}>
      <CongresLayout>
        <Router basepath="/congres">
          <HomeIndex path="/" />
          <Wrapper path="expo">
            <ExpoIndex path="/" />
            <Pierrefabre path="/pierrefabre" />
            <Novartis path="/novartis" />
            <Accentis path="/accentis" />
            <Optimhal path="/optimhal" />
            <Gsk path="/gsk" />
            <Genericlab path="/genericlab" />
            <Sanofi path="/sanofi" />
            <Inphamedis path="/inphamedis" />
            <BauschLomb path="/bauschlomb" />
            <Beker path="/beker" />
            <Elkendi path="/elkendi" />
            <Pharmalliance path="/pharmalliance" />
            <Astrazeneca path="/astrazeneca" />
            <Biopharm path="/biopharm" />
            <Magpharm path="/magpharm" />
            <Chiesi path="/chiesi" />
            <Takeda path="/takeda" />
            <Fraterrazes path="/fraterrazes" />
          </Wrapper>
          <AuditoriumIndex path="auditorium" />
          <PostersIndex path="posters" />
          <CommunicationsIndex path="communications" />
          <ProgramIndex path="program" />
          <SpeakersIndex path="speakers" />
        </Router>
      </CongresLayout>
    </div>
  )
}

