import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Form, Menu, Modal, Row } from "antd"

import "../../../../../style/navbar.css"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FileImageOutlined, LinkOutlined, MailOutlined, PhoneOutlined,
  PlayCircleOutlined, UserOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"

const { SubMenu } = Menu
export default function Beker() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Beker"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/beker.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item icon={<DesktopOutlined className={"icon2"} />}
                       onClick={() => setupModal("presentation")}>
              Présentation
            </Menu.Item>
            <Menu.Item icon={<ContactsOutlined className={"icon2"} />}
                       onClick={() => setupModal("contact")}>
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FileImageOutlined />}>
                <a href={"https://ubiquity.business/saaic/beker/1.png"}
                   target="_blank" download style={{ fontSize: 18 }}>B2R 1</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href={"https://ubiquity.business/saaic/beker/2.png"}
                   target="_blank" download style={{ fontSize: 18 }}>B2R 2</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href={"https://ubiquity.business/saaic/beker/3.png"}
                   target="_blank" download style={{ fontSize: 18 }}>B2R 3</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href={"https://ubiquity.business/saaic/beker/4.png"}
                   target="_blank" download style={{ fontSize: 18 }}>B2R 4</a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item icon={<PlayCircleOutlined />}
                       onClick={() => setupModal("video")}>
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Beker"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <p>
      Les Laboratoires BEKER est un laboratoire pharmaceutique algérien
      d’excellence, spécialisé dans le développement, la production, la
      promotion et la commercialisation de médicaments génériques.
    </p>
    <p>
      Animé par les valeurs d’excellence, d’Engagement et d’Equité ; nous
      oeuvrons pour le bien être du patient et le mettons au centre de nos
      intérêts. Nous avons pour mission de rendre accessibles à tous des
      thérapies de dernière génération répondant aux standards de qualité
      internationaux, avec plus de 160 références reparties sur 10 aires
      thérapeutique : la cardiologie, le système nerveux central , le
      métabolisme , la gastrologie , l’hépatologie , l’urologie ,
      l’infectiologie , la rhumatologie , la douleur et l’allergologie.
    </p>
    <p>
      Parallèlement aux médicaments génériques et dans un souci d’accompagner
      les patients au quotidien, nous avons devéloppé et mis sur le marché des
      compléments alimentaires 100 % naturels. B2R Back To Roots, une gamme
      variée qui cible des indications diverses pour renforcer l’immunité,
      améliorer la vivacité, le confort, la beauté et la santé féminine.
    </p>
    <p>
      Nos collaborateurs portent le savoir-faire BEKER avec exigence et volonté
      et assurent une veille nous permettant une mise à jour régulière de nos
      connaissances et de nos équipements pour mieux servir la communauté.
    </p>
  </div>

const video =
  <ReactPlayer
    url={"https://ubiquity.business/saaic/beker/v1.mp4"}
    controls
    width={1024}
    height={576}
  />

const contact =
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 (0) 23 83 42</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:contact@bekerlaboratoires.com">contact@bekerlaboratoires.com</a>
    </Form.Item>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://www.backtoroots-b2r.com/" target="_blank">www.backtoroots-b2r.com</a>
    </Form.Item>
  </Form>