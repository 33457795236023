import React, { useEffect, useState } from "react"
import ReactPannellum from "react-pannellum"
import LobbyMain from "../../../images/360/lobbymain.jpg"
import LobbyDesk from "../../../images/360/lobbydesk.jpg"

import "../../../style/buttons.css"
import Seo from "../../seo"
import { lobbydesk, lobbymain } from "../../../utils/hotspots/lobby"
import { navigate } from "gatsby-link"
import ReactPlayer from "react-player"
import { Modal,Typography } from "antd"
const {Title}=Typography
export default function HomeIndex() {
  const [modalInfos, setModalInfos] = useState({
    visible: false,
    url: "",
    title: ""
  })
  const mainConfig = {
    imageSource: LobbyMain,
    hfov: 120,
    yaw: 4,
    autoLoad: true,
    uiText: {
      loadingLabel: "Chargement..."
    },
    doubleClickZoom: true,
    hotSpots: lobbymain
  }
  
  const deskConfig = {
    type: "equirectangular",
    imageSource: LobbyDesk,
    uiText: {
      loadingLabel: "Chargement..."
    },
    hfov: 120,
    yaw: 4,
    autoLoad: true,
    doubleClickZoom: true,
    hotSpots: [
      {
        type: "scene",
        pitch: -5,
        yaw: -10,
        text: "Entrée",
        sceneId: "main"
      },
      {
        type: "scene",
        pitch: -4.7,
        yaw: -47,
        text: "Salle d'exposition",
        clickHandlerFunc: () => navigate("/congres/expo")
      },
      {
        type: "scene",
        pitch: -4.7,
        yaw: 24.4,
        text: "Salle d'exposition",
        clickHandlerFunc: () => navigate("/congres/expo")
      },
      {
        type: "scene",
        pitch: -6.3,
        yaw: 163,
        text: "Auditorium",
        clickHandlerFunc: () => navigate("/congres/auditorium")
      },
      {
        type: "scene",
        pitch: 6.5,
        yaw: 125,
        text: "Programme",
        clickHandlerFunc: () => navigate("/congres/program")
      },
      {
        type: "scene",
        pitch: 8.1,
        yaw: 149,
        text: "Orateurs"
      },
      {
        type: "scene",
        pitch: 8.1,
        yaw: -179,
        text: "Communications Orales",
        clickHandlerFunc: () => navigate("/congres/posters")
      },
      {
        type: "scene",
        pitch: 7,
        yaw: -154,
        text: "E-Posters",
        clickHandlerFunc: () => navigate("/congres/posters")
      },
      {
        type: "info",
        pitch: -33,
        yaw: -22.7,
        text: "Video",
        clickHandlerFunc: () => setModalInfos({
          ...modalInfos,
          visible: true,
          url: "https://player.vimeo.com/video/567253216",
          title: ""
        })
      }
    ]
  }
  useEffect(() => {
    setTimeout(() => ReactPannellum.addScene("desk", deskConfig))
  }, [])
  
  return (
    <>
      <Seo title="Hall" />
      <ReactPannellum
        id="main"
        style={{
          width: "100%",
          height: "92vh"
        }}
        sceneId="main"
        imageSource={LobbyMain}
        config={mainConfig}
      />
      <Modal
        width={"auto"}
        onCancel={() => setModalInfos({ ...modalInfos, visible: false })}
        title={<Title level={4}>Bienvenu</Title>}
        visible={modalInfos.visible}
        centered
        footer={null}
        destroyOnClose
      >
        <ReactPlayer
          url={modalInfos.url}
          controls
          width={1024}
          height={576}
        />
      </Modal>
    </>
  )
}