import React from "react"
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"

export default function Timeline({ elements }) {
  return (
    <VerticalTimeline layout="1-column-left">
      {elements.map((element, key) => (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={element.date}
          key={key}
        >
          <h3
            className="vertical-timeline-element-subtitle">{element.description}
          </h3>
          <h4
            className="vertical-timeline-element-subtitle">{element.speaker}
          </h4>
          <h5>{element.title}</h5>
        </VerticalTimelineElement>
      ))}
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
      />
    </VerticalTimeline>
  )
}