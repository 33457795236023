import React, { useEffect, useState } from "react"
import { day1, day2, day3 } from "../../../utils/program"
import {
  CheckOutlined,
} from "@ant-design/icons"

import { Grid, Steps, Tabs } from "antd"

const { useBreakpoint } = Grid
const { Step } = Steps
const { TabPane } = Tabs

export default function Timeline(props) {
  const { setUrl } = props
  const [step, setStep] = useState(0)
  const [selectedTab, setSelectedTab] = useState("3")
  const screens = useBreakpoint()
  useEffect(() => {
    setUrl(day3[step ? step : 0].url)
  }, [])
  
  
  function onSelectStep(step) {
    setStep(step)
    if (selectedTab === "1") {
      setUrl(day1[step].url)
    }
    if (selectedTab === "2") {
      setUrl(day2[step].url)
    }
    if (selectedTab === "3") {
      setUrl(day3[step].url)
    }
  }
  
  function changeTab(key) {
    
    setStep(0)
    setSelectedTab(key)
    switch (key) {
      case "1":
        setUrl(day1[0].url)
        break
      case "2":
        setUrl(day2[0].url)
        break
      case "3":
        setUrl(day3[0].url)
        break
    }
  }
  
  return (
    <Tabs
      style={{
        width: "100%",
        height: "100%"
      }}
      defaultActiveKey={selectedTab}
      type={"card"}
      centered
      onTabClick={(key) => changeTab(key)}
    >
      <TabPane
        tab="J 1"
        key="1"
        style={{
          overflow: "scroll",
          backgroundColor: "white",
          maxHeight: screens.xs ? 300 : "85vh",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10
        }}
      >
        <Steps
          size="small"
          current={step}
          direction="vertical"
          onChange={onSelectStep}
        >
          {day1.map((content, key) => (
            <Step
              title={content.title}
              description={content.description}
              icon={<CheckOutlined />}
              key={key}
            />
          ))}
        </Steps>
        <br />
        <br />
        <br />
      </TabPane>
      <TabPane
        tab="J 2"
        key="2"
        
        style={{
          overflow: "scroll",
          backgroundColor: "white",
          maxHeight: screens.xs ? 300 : "85vh",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10
        }}
      >
        <Steps
          size="small"
          current={step}
          direction="vertical"
          onChange={onSelectStep}
        >
          {day2.map((content, key) => (
            <Step
              title={content.title}
              description={content.description}
              icon={<CheckOutlined />}
              key={key}
            />
          ))}
        </Steps>
        <br />
        <br />
        <br />
      </TabPane>
      <TabPane
        tab="J 3"
        key="3"
        style={{
          overflow: "scroll",
          backgroundColor: "white",
          maxHeight: screens.xs ? 300 : "85vh",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10
        }}
      >
        <Steps
          size="small"
          current={step}
          direction="vertical"
          onChange={onSelectStep}
        >
          {day3.map((content, key) => (
            <Step
              title={content.title}
              description={content.description}
              icon={<CheckOutlined />}
              key={key}
            />
          ))}
        </Steps>
      
      </TabPane>
    </Tabs>
  )
}