const posters = [
  {
    title: "Syndrome de la pince aorto-mésentérique associé à une maladie auto-immune. A propos d’un cas avec revue de la littérature",
    authors: "M.BERRAH, F.ZERKAOUI,A.GOUICHICHE,L.CHENANE,M.BOUCHOUAREB, A.HADJI, S.BOUMEDINE,S.AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/366caae7587d4bada8ce29db71b2cb39"
  },
  {
    title: "La maladie de gaucher et maladies auto-immunes: A propos de 02 ca",
    authors: "S. ZITOUNI, C. OUARAB, N. DAHMAN, M. GUETTOUCHE, F. FELLAHI, F. HAMITI, F.TOUMIAT, K. BENAICHA, A. BOUGADOUM, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/54ef09df65354bf8a39c19099757fa7f"
  },
  {
    title: "La polychondrite atrophiante révélée pas une manifestation ischémique: A propos d’un cas",
    authors: "S. ZITOUNI, N. DAHMAN, N. LYAZIDI, C. OUARAB, Y. BENOUALI, A. HADJI, K. KHALFA, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/976ce14701524a949cef00845153a87c"
  },
  {
    title: "Les maladies auto-immunes et grossesse",
    authors: "S. ZITOUNI, N. DAHMAN, M. BERRAH, C. OUARAB, Y. SMAIL, M. YOUSFI, L.GAMAZ, S.BOUMEDINE,S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/59dbe1cd4e5c4a129c56b7d2cf44a02e"
  },
  {
    title: "Pneumomédiastin au cours de la dermatomyosite à MDA5 : A propos d’un cas",
    authors: "Z. MOKRANE, C. OUARAB, N.DAHMAN, N. LADJANI, M. YOUSFI, K. BENAICHA, S.AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/c7a4bce0664944c7926f72e154e4a20e"
  },
  {
    title: "La Myocardite Lupique : A propos de deux cas",
    authors: "A. HADJI, N. DAHMAN, C. OUARAB, M. BERRAH, L. CHENANE, I. GOUICHICHE, Z.MOKRANE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/3e043c7303c8495dbf4ddd7d62299598"
  },
  {
    title: "Thromboses intracardiaques en médecine interne",
    authors: "A. HADJI, M. BERRAH, C. OUARAB, N. DAHMAN, K. KHAKFA, A. MANSOURI, H.BENDJEMAI, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/3be24cc9748d4482a9e8a7fbf6abbead"
  },
  {
    title: "Les atteintes pulmonaires des pathologies auto-immunes à révélation tardive",
    authors: "A. HADJI, N. DAHMAN, C. OUARAB, M. BERRAH, L.CHENANE, I. GOUICHICHE, \n" +
      "Z. MOKRANE, S. AYOUB(Béni-messous)",
    url: "https://www.easyzoom.com/embed/6925c47e839c4b889e75b5b000292ecf"
  },
  {
    title: "L’atteinte cardio-pulmonaire au cours de la dermatomyosite : A propos d’un cas",
    authors: "F. FELLAHI, M. GUETTOUCHE, C. OUARAB, K. KHALFA, A. HADJI, M.H. BOUCHOUAREB, N. KHABACHE, I. SLIMANI, M. BERRAH, S.AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/045e975cb36349a294c46e3f79303b38"
  },
  {
    title: "Fibrose rétro-péritonéale : expérience du service",
    authors: "F. FELLAHI, S. ZITOUNI, N. LYAZIDI, N. DAHMAN, S. LARBANI, H. BENDJEMAI, A. MANSOURI, L. GAMAZ, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/7155ff4ced3e43f3bebfcc424338a29c"
  },
  {
    title: "Overlap syndrome HAI/CBP : A propos de 2 cas",
    authors: "I. SlIMANI, F. ZERKAOUI, N. GHEZAL, F. TOUMIET, K. KHALFA, Y. BENOUALI, S.BOUMEDINE, S.AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/f8b482fd87b94883acfa4dc8b8110c6c"
  },
  {
    title: "Maladie lupique de diagnostic ardu: A propos d’un cas",
    authors: "N.KHABECH, C. OUARAB, F. ZERKAOUI, N. LYAZIDI, N. LADJANI, N. DAHMAN, L. GAMAZ, A. CHENANE, S. BOUMEDINE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/1bcfc2d131184021a06ab5c28acfd206"
  },
  {
    title: "Syndrome de Hughes-Stovin et complication infectieuse: aspergillose pulmonaire: A propos d’un cas",
    authors: "N. KHABECH, C.OUARAB, M .BERRAH,N.LYAZIDI, N. LADJANI, N. DAHMAN, L. GAMAZ, A. CHENANE,S AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/c018e40bd6d640eba98d21b214ab71d1"
  },
  {
    title: "Pancréatite aigue et COVID 19",
    authors: "H. BENDJEMAI, F. ZERKAOUI, N. LYAZIDI, N. DAHMAN, Y. SMAIL, A. BOUGADOUM, I. GOUICHICHE,M. BERRAH, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/8ee19f177b194707ad2c871ae270167d"
  },
  {
    title: "Obésité et COVID 19",
    authors: "F. ZERKAOUI, N. LYAZIDI, F. HAMITI, M. YOUSFI, A. MANSOURI, I. SLIMANI, M.H. BOUCHOUAREB, C. OUARAB, S. AYOUB(Béni-messous)",
    url: "https://www.easyzoom.com/embed/aa42d8398d654337a0bc0dbdd451051c"
  },
  {
    title: "Manifestations pulmonaires au cours des HPN: A propos de 3 cas",
    authors: "F. ZERKAOUI, S. LARBANI, M.H. BOUCHOUAREB, N. DAHMAN, C. OUARAB, S. BOUMEDINE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/1f4151abbc114ea2afa9b5b2a09eb65c"
  },
  {
    title: "Traitement de la PID associée à la dermatomyosite à anticorps anti-MDA5 : A propos de deux cas",
    authors: "A.MESBAHI, N.LYAZIDI, F.ZERKAOUI, Z.MOKRANE, Y.BENOUALI, M.GOUICHICHE, C.OUARAB , S.AYOUB(Béni-messous)",
    url: "https://www.easyzoom.com/embed/c4bc7b73e9334e2ebba28ec7827d6e80"
  },
  {
    title: "Thrombophilie constitutionnelle, expérience du service de médecine interne",
    authors: "M.MANSOURI, N. LYAZIDI, A. MESBAHI, S. LARBANI, M.H. BOUCHOUAREB, Z. MOKRANE, C. OUARAB, S. BOUMEDINE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/fd954eb5ecb54858bd47fa0f37f21a06"
  },
  {
    title: "Profil évolutif de la pneumopathie COVID 19 à 3 mois : expérience du service de Médecine interne",
    authors: "C.OUARAB, F. ZERKAOUI, M. BERRAH , N. DAHMAN, N.LYAZIDI, A. RIZOU, N. LADJANI, S. BOUMEDINE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/"
  },
  {
    title: "Pathologies auto-immunes chez les sujets âgés en médecine interne : aspects épidémiologiques et cliniques",
    authors: "C.OUARAB, A. HADJI, N. KHABECH, M. BERRAH , N. DAHMAN, F. ZERKAOUI, S. BOUMEDINE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/87b78e5a5ab4444c8cff80c9b06b5ab2"
  },
  {
    title: "Symptômes atypique de la maladie cœliaque",
    authors: "F. ZERKAOUI, S. LARBANI, F. FELLAHI, F. HAMITI, Y. BENOUALI, K. KHALFA, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/611cc6bc52b3465b9d251fcaedf0cead"
  },
  {
    title: "Manifestations ORL de la sarcoïdose: A propos de 4 cas",
    authors: "N. DAHMAN, M. BERRAH, N. LYAZIDI, S. ZITOUNI, Y.SMAIL, L. CHENANE, I. GOUICHICHE, A.MANSOURI, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/cc1cb2388d5e4cf1994adfada17ef39b"
  },
  {
    title: "Œil et maladies auto-immune",
    authors: "S.ZITOUNI, C. OUARAB, N. DAHMAN, F. ZERKAOUI, A. HADJI,H.BENDJAMAI, A.MANSOURI, L. GAMAZ, S.AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/e7023de80c74467cab2871616a84b106"
  },
  {
    title: "Vaccination anti-covid-19: expérience d’un service de médecine du travail",
    authors: "M.HAMADOUCHE , A.BOUKRAA , S.ABBASSENE, H.GUENNICHE, H.HADDAD ( Service de médecine du Travail ( CHU de Sétif))",
    url: "https://www.easyzoom.com/embed/2ff7ede94038404b859a8a1c46498fad"
  },
  {
    title: "Etude exhaustive du syndrome des bâtiments malsains d’un hôpital à l’ouest Algérien",
    authors: "A.BENHADJ, C.B. TEBBOUNE, M. HANBA, K. HIMI  (Oran)",
    url: "https://www.easyzoom.com/embed/395d353f39914fcbb5d0e090ef50bf85"
  },
  {
    title: "Symptômes inexpliqués : enquête dans une structure hospitalière",
    authors: "A.BENHADJ, C.B. TEBBOUNE , M. HANBA, K. HIMI (Oran)",
    url: "https://www.easyzoom.com/embed/4861ae072c6f44429f374e13d763517b"
  },
  {
    title: "Gestion de la covid19 dans une entreprise du bâtiment et travaux publics",
    authors: "Z. KHOUDOUR, I. BOUDERBAL, S. HAMIDA,N.AKIF, N. LIANI (CPMC)",
    url: "https://www.easyzoom.com/embed/2430f2b2b8fb49fb8947eca2ce697f8f"
  },
  {
    title: "Evaluation du risque de contamination par le Covid 19 dans une entreprise de télécommunications",
    authors: "S.HAMIDA , N.AKIF, S.CHEBOUCHE, A.LAMARA MAHAMED ,N.LIANI (CPMC)",
    url: "https://www.easyzoom.com/embed/"
  },
  {
    title: "Vaccination covid 19: expérience d’un service de médecine du travail dans un EHS en cancérologie",
    authors: "S.CHEBOUCHE ,Z.KHOUDOUR , N.AKIF , N.LIANI (CPMC)",
    url: "https://www.easyzoom.com/embed/40cdd992bb9b4d899bb9a78e42b67b0a"
  },
  {
    title: "Prise en charge du personnel hospitalier d’un centre anti cancer au niveau d’Alger dans le contexte de la pandémie de la Covid19",
    authors: "M.GHILLASSENE , M.KERBOUDJ ,  B.TOUATI ,   A.LABANI , M.AKSSAS, A.BACHA , N.LIANI (CPMC)",
    url: "https://www.easyzoom.com/embed/81487baf5b6b4b4082cb2eb30900f595"
  },
  {
    title: "Dysidrose palmoplantaire et allergie professionnelle: A propos d’un cas",
    authors: "HACHANI, N.AKIF ,A. LAMARA MAHAMED ,N.LIANI (CPMC)",
    url: "https://www.easyzoom.com/embed/025520822b90438796805470cb5503ad"
  },
  {
    title: "Histiocytose langerhansienne révélée par un pneumothorax chez un nourrisson: A propos d’un cas",
    authors: "S.BENMAOUCHE, L.SEKFALI, H.BOUCENNA , N.BOUTERFAS, N.BOUKHEDOUMA, A.MOHAND OUSSAID, F.BOUFEROUA, S.BAASOU, K.N.BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/ef7d747d9fbb418da205c6aff3168016"
  },
  {
    title: "Pneumopathie d’hypersensibilité de l’enfant: il faut y penser!",
    authors: "S.BENMAOUCHE,L.SEKFALI,N.BOUKHEDOUMA,H.BOUCENNA,F.BOUFEROUA, N.DAHMANE,Y.K. HADRI,D.AMOUCHI, M.NAAMANE,K.N.BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/63fd2864affa417ca9ccb0af16b29ad6"
  },
  {
    title: "Aspergillose broncho-pulmonaire allergique révélant une mucoviscidose chez un grand enfant: A propos d’une observation",
    authors: "S.BENMAOUCHE,L.SEKFALI,N.BOUKHEDOUMA,A.MOHANDOUSSAID,I.BOUZIDI,N.BOUTERFAS,N.DAHMANE,M.SAIDANI,K.N.BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/42faaf31c06845da9125efae8a01980a"
  },
  {
    title: "Et si c’était une pleurésie tuberculeuse?",
    authors: "O.DIF, L.SEKFALI, N.BOUKHEDOUMA,A.MOHAND OUSSAID, N.BOUTERFAS, H.BOUCENNA, F.BOUFEROUA,K.HADJAB, K.N.BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/f9691acaa7b744a693cff6a51024010a"
  },
  {
    title: "Pneumonie persistante révélant une tumeur endobronchique chez une enfant de 8 ans.",
    authors: "O.DIF,L.SEKFALI,N.BOUKHEDOUMA,I.BOUZIDI,H.BOUCENNA,F.BOUFEROUA, Y.BOUSKIA,D.AMOUCHI, M.NAAMANE, K.N.BENHALLA (Béni- messous)",
    url: "https://www.easyzoom.com/embed/51e526f92dc143ab8942971506d0243c"
  },
  {
    title: "Grossesses compliquées d’infection au SARS-COV2, qu’en est-il des nouveau-nés?",
    authors: "I.BOUZIDI, S. SANSAL, A. SARI, Y. KHADRI, Y. BOUSKIA, L.SEKFALI, F. BOUFEROUA, BELAID, N. DJIDJIK,N.K BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/d1be50c943a94786b3ff6bf6338283ea"
  },
  {
    title: "Infection au SARS-CoV-2: le syndrome inflammatoire multisystemique de l'enfant (Mis-c):  A propos d’un cas",
    authors: "S.SANSAL, I.BOUZIDI, M.NAAMANE,H.ALLALOU,Y.BOUSKIA, H.BOUCENNA, L.SEKFALI, K.N.BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/db023a81d27d4486b100fd369f3b52b2"
  },
  {
    title: "Syndrome de Wiskott Aldrich à révélation Néonatale : A propos de 2 observations aves revue de la littérature",
    authors: "A.DJELILI, B.SAIHI, O.DRALI, B.BELAID, T.Mellah, A.CHIKH,A.BENOUARETH,K.KETTAB, R.DJIDJIK,A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/708f3813497d4119bc530aa8bb1987ac"
  },
  {
    title: "Syndrome d’Omenn : Mode de révélation et évolution: A propos d’un cas avec revue de la littérature",
    authors: "N.GUERNOUTI, S.DOUKAR, O.DRALI, B.BELAID, T.MELLAH, A.CHIKH ,A.BENOUARETH, K.KETTAB, R.DJIDJIK, A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/6a18eeb95fae472fbda9aa8cb7e8936e"
  },
  {
    title: "Déficit immunitaire primitif du au défaut d’expression des moléculaires HLA-DR : à propos de 3 observations",
    authors: "H.HAFSI , T.MELLAH , O.DRALI, B.BELAID, A.DOUKAR, N.GUERNOUTI, A.DJELIL, L.REFSI, R.DJIDJIK, A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/e2502afb35a2463ebb4054703b38d8f3"
  },
  {
    title: "Dystrophie musculaire d’Ullrich associée à une gammaglobulinemie congénitale liée à I’X’’",
    authors: "A.DEFFOUS, O.DRALI, B.BELAID, R.DJIDJIK, A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/71ac6f3f14ad4ca6b64de95832812885"
  },
  {
    title: "Fièvre prolongée : Penser à une hyper éosinophilie",
    authors: "A.DEFFOUS, O.DRALI, B.BELAID, R.DJIDJIK, A.MEKKI(Service Pédiatrie B.CHU Hussein Dey (Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/84ae16d061724a0ab51b8248e6d9b4c4"
  },
  {
    title: "Granulomatose septique chronique: différents aspects cliniques",
    authors: "T.Mellah, A.TEBANI, A. CHAIRI, O.DRALI, B.BELAID, H.KARIMA, M.BOUTABA, R.DJIDJIK, A.MEKKI. (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet),Alger)",
    url: "https://www.easyzoom.com/embed/d15c4fb0a9014b079afbcb58b7a92da2"
  },
  {
    title: "Cartilage Hair Hypoplasia: A propos d’une observation avec revue de la littérature",
    authors: "N.A.GUERNOUTI,O.DRALI.B.BELAID,T.MELLAH,A.CHIKH,A.BENOUARETH,K.KETTAB, R.DJIDJK, A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet),Alger)",
    url: "https://www.easyzoom.com/embed/4a1b98d681fc49b5ae7a4125adec8afe"
  },
  {
    title: "Neutropénie Congénitale sévère: Aspect clinique et paracliniques : A propos d’un cas avec revue de la littérature",
    authors: "A.CHIKH,O.DRALI,B.BELAID, R.DJIDJIK, A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/1f3fd47940d24c679c5f47f9c62e6161"
  },
  {
    title: "Déficit immunitaire combiné sévère et son variant: A propos de 3 observations",
    authors: "A.BENOUARETH, O.DRALI, B.BELAID, R.DJIDJIK, A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex Parnet), Alger)",
    url: "https://www.easyzoom.com/embed/a3fdb29e8eec4468aa7a7ce2f207790d"
  },
  {
    title: "Profil épidémiologique et clinique des enfants suivis pour APLV",
    authors: "O.DRALI, A.CHIKH, I.KETAB ,  M.DJELIL , L.REFSI, B.BENYAHIA, N.AMROUNE , A.MEKKI  (Service Pédiatrie B , CHU Hussein Dey(Ex PARNET))",
    url: "https://www.easyzoom.com/embed/375bace35f544b1192ebd3a5bbd8187c"
  },
  {
    title: "Titre DÉFICIT IMMUNITAIRE COMMUN VARIABLE CHEZ L’ENFANT: Description clinique et immunologique de 02 observations",
    authors: "I.KETTAB, O.DRALI, S.HAOUAS, B.BELAID, T.MELLAH, S.DOUKAR, N.GUERNOUTI, A.CHIKH, K.BENOUARETH, M.DJELIL, L.REFSI, R.DJIDJIK, A.MEKKI",
    url: "https://www.easyzoom.com/embed/3dfb9c5dc20c49a993d5558773412c30"
  },
  {
    title: "Dermatite atopique chez l’enfant : caractéristiques cliniques et paracliniques des enfants suivis en consultation.portrait",
    authors: "O.DRALI, N.GHERNOUTI ,S.DOUKAR , T.MELLAH ,A. CHIKH,I.KETAB, M.DJELIL,L.REFSI, N.AMROUNE,A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex PARNET))",
    url: "https://www.easyzoom.com/embed/260cc8c135de48f1a8e79dd5c74e11fc"
  },
  {
    title: "Complications de la dermatite atopique: A propos de 20 observations.portrait",
    authors: "O.DRALI,I.KETAB,M. DJELIL, L.REFSI,N.GHERNOUTI ,S.DOUKAR , T.MELLAH, A.CHIKH-BENOUARET, N.AMROUNE,A.MEKKI (Service Pédiatrie B.CHU Hussein Dey(Ex PARNET))",
    url: "https://www.easyzoom.com/embed/aba9088ec3b245a68b097b60efaa27a8"
  },
  {
    title: "Asthme et tabac: données de la littérature",
    authors: "L.IHADJADENE- BOUAYAD , R.ABDELLAZIZ (Tizi ouzou)",
    url: "https://www.easyzoom.com/embed/c16ca91402d04df48a0894ba14c688b9"
  },
  {
    title: "Epanchement pleural éosinophile révélant un syndrome de CHURG et STRAUSS, A propos d’un cas",
    authors: "D.HACID ,  H.YALA , G. MALKI(Bejaia)",
    url: "https://www.easyzoom.com/embed/f51a3031ee3944d3a0b2d62849e1a907"
  },
  {
    title: "Thoracic manifestations of Takayasu disease in humans : About three cases",
    authors: "N. LYAZIDI, M.H. BOUCHOUAREB, N. KHABACHE, F. ZERKAOUI, N. DAHMAN, M.BERRAH,S.BOUMEDINE, S.AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/8595d6d0fd284a0fae47eff95bebeada"
  },
  {
    title: "Systemic scleroderma revealing adenocarcinoma",
    authors: "K. BENAICHA , N.LYAZIDI , M. GUETTOUCHE , N. GHEZAL , S. BOUMEDINE , S. AYOUB  (Béni-messous)",
    url: "https://www.easyzoom.com/embed/e31e58d731ae4c68b28552134ca817e1"
  },
  {
    title: "Reynolds syndrom a presentation : About three cases report",
    authors: "A. MANSOURI, F. ZERKAOUI, S. LARBANI, M.H. BOUCHOUAREB, N. DAHMAN, C.OUARAB, S. BOUMEDINE, S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/af208bad210e4e27bf68414056ffb828"
  },
  {
    title: "Chronic superficial urticaria associated with solid cancers",
    authors: "F .ZERKAOUI, A. MANSOURI, L. LYAZIDI, S. AYOUB (Béni-messous) Médecine du travail",
    url: "https://www.easyzoom.com/embed/1e68d0850ae14e9d8f4819cbafbb66c8"
  },
  {
    title: "Suivi de l’aeromycoflore au domicile de sujets asthmatiques à Beni-Saf et évaluation de sa corrélation avec la symptomatologie clinique",
    authors: "H.DERRAR, Y.MERAD, I.KHALDI, A.TALEB, S Pneumologie , Laboratoire central; CHU « Hassani Abdelkader » SBA, Algeria",
    url: "https://www.easyzoom.com/embed/f086b31e047d498aa8c98060d2d2155c"
  },
  {
    title: "Feet fungal flora of immunosuppressed patients: a cross-sectional study",
    authors: "Y.MERAD, H.DERRAR, A.TALEB, (Sidi-bellabès). " +
      "Laboratoire central, S Pneumologie, CHU « Hassani Abdelkader » SBA, Algeria",
    url: "https://www.easyzoom.com/embed/4224e91a4b984f8691e947ceeb7b2d02"
  },
  {
    title: "Evaluation du risque fongique à partir de l’air et des tapis des mosquées de Sidi-Bel-Abbès",
    authors: "H.DERRAR, Y.MERAD, B.OUGUIR, A.TALEB, S Pneumologie, Laboratoire central; CHU « Hassani Abdelkader » SBA, Algeria",
    url: "https://www.easyzoom.com/embed/dae6048663fa43518803f065c392137e"
  },
  {
    title: "Fungal contamination of insects in hospital settings: is it a real risk of pulmonary manifestations?",
    authors: "H.DERRAR, Y.MERAD, S.BERNI, A.TALEB (Sidi-bellabès)",
    url: "https://www.easyzoom.com/embed/f68c7f7b6ff14199a99019275a9fbbde"
  },
  {
    title: "Onychogryphosis due to Aspergillusnigerin a poorly controlled type 2 diabetes mellitus",
    authors: "Y.MERAD, H.DERRAR, M.BELKACEMI, T.LANSARI, A.TALEB (Sidi-bellabès)",
    url: "https://www.easyzoom.com/embed/21188407c0bf4abf942d8af26174b9cf"
  },
  {
    title: "Unexpected crusted scabies in an elderly woman without any immunosuppression",
    authors: "Y.MERAD , H.DERRAR, L.THORAYYA, A.TALEB (Sidi- bellabès)",
    url: "https://www.easyzoom.com/embed/9d2e511a5e884a5e9544815dc1bab430"
  },
  {
    title: "Adénome pléomorphe des cavités nasosinusiennes: étude d’un cas",
    authors: "M.SELLAMI, S.DEHAS, A.TAHRAOUI, R.DABEL, Y.KHIAL (Bejaia)",
    url: "https://www.easyzoom.com/embed/6f30b837b3f3452b92ecb499b291d19d"
  },
  {
    title: "Les mucocèles spontanées: étude de trois cas",
    authors: "M.SELLAMI, R. DABEL, S. AKBAL, C. TALBI, S.DEHAS, S.ZEKIOU (Bejaia)",
    url: "https://www.easyzoom.com/embed/b9976f4fb23e4841830bfdda537d784e"
  },
  {
    title: "Qualité de vie, polypose nasosinusienne et chirurgie",
    authors: "M.SELLAMI, S. AKBAL, C. TALBI, R. DABEL, S. DEHAS, S.ZEKIOU (Bejaia)",
    url: "https://www.easyzoom.com/embed/381204c4e5234f1782474fa3d93b46bf"
  },
  {
    title: "SNOT 22: son intérêt en rhinologie",
    authors: "M.SELLAMI, A.TAHRAOUI, K. DELLAL, W. REBAI, Y. KHIAL (Bejaia)",
    url: "https://www.easyzoom.com/embed/8f4c30255d4948bab34e94806d2e897c"
  },
  {
    title: "Un cas de papillome inversé nasosinusien dégénéré à extension endocrânienne",
    authors: "M.SELLAMI  , W.REBAI  , F. BOUDJENAH  , H. KHECHFOUD  ,S.TLIBA  , K.DELLAL, Y.KHIAL(Bejaia)",
    url: "https://www.easyzoom.com/embed/bc5084136c704dcd8cac57cc183d7141"
  },
  {
    title: "Histiocytose X pulmonaire: A propos d’un cas",
    authors: "H.KAOUANE, R.TOUAHRI, A.KETFI (Rouiba)",
    url: "https://www.easyzoom.com/embed/6e59c10d62d14063a6a96244e108a62a"
  },
  {
    title: "Expression scannographique pseudotumorale de la COVID-19: A propos d’un cas",
    authors: "H.Chellal, R. Touahri, A.Ketfi (Rouiba)",
    url: "https://www.easyzoom.com/embed/8139437f75e34ebfb6efda8b38f4cddd"
  },
  {
    title: "Plasmocytome médiastinal: A propos d’un cas",
    authors: "H. CHELLAL, H. BEGGA, I. LAOUEDJ, R. TOUAHRI, A. KETFI (Rouiba)",
    url: "https://www.easyzoom.com/embed/b07bfd17214e4368b73acf39b8b1b3c4"
  },
  {
    title: "Un syndrome de chevauchement avec une PID: A propos d’un cas",
    authors: "M. MESSAOUDI, H. BEGGA, R. TOUAHRI,  A.KETFI (Rouiba)",
    url: "https://www.easyzoom.com/54ffe822372b4220a69aa19604820c43"
  },
  {
    title: "Infection tuberculeuse chez les patients atteints de maladie de Crohn traités par anti-TNFα: A propos de 1 cas",
    authors: "M. MESSAOUDI, R. TOUAHRI, A. KETFI (Rouiba)",
    url: "https://www.easyzoom.com/embed/ee7e5543045742ee9ba356df681a5cc6"
  },
  {
    title: "Profil clinique et para clinique chez les patients asthmatiques atteints d’infection COVID-19",
    authors: "R.TOUAHRI , A.KETFI (Rouiba)",
    url: "https://www.easyzoom.com/embed/0fdcffb78f914028a0c8659aa92d681c"
  },
  {
    title: "Pneumo médiastin au cours du COVID 19: à propos de 2 cas Revue de la littérature",
    authors: "N. LYAZIDI, N.DAHMAN ,K.KHALFA , Z .MOKRANE ,A. HADJI , N.KHEBACHE ,I. SLIMANI , A. MESBAHI , S. AYOUB (Béni-messous)",
    url: "https://www.easyzoom.com/embed/4447f94541924c79bee29407f7b47add"
  },
  {
    title: "Evaluation du risque lié au COVID 19: experience au sein d'une Entreprise de télécommunications",
    authors: "S.Hamida, S.Layadi, N.Akif, N.Liani",
    url: "https://www.easyzoom.com/embed/bc765ebec0454e0bbf67a4c796520836"
  },
  {
    title: "Sinusite maxillaire plus, apport de l'IRM",
    authors: "D.AID, A.LEBCIR, N.BOUBENDIR",
    url: "https://www.easyzoom.com/embed/3b1e23ad760e4bccb018f62a22b4a223"
  },
  {
    title: "Imagerie de l'esthesioneuroblastome, apport de l'IRM",
    authors: "D.AID, A.LEBCIR",
    url: "https://www.easyzoom.com/embed/25d8d06a822d432d924c919375018458"
  },
  {
    title: "Décision d’inaptitude au travail en circuit  Covid d’un personnel hospitalier",
    authors: "D.MAACHA, A.ARIB-MEZDAD, R.CHERRAD. CHU Tizi Ouzou",
    url: "https://www.easyzoom.com/embed/89c70bc0faf4423bad1e7bfd4940058a"
  },
  {
    title: "COVID-19 en milieu hospitalier:etat des lieux",
    authors: "A.CHETOUANI-ZENNOUCHE, A.ARIB-MEZDAD, F.SAIDI. CHU Tizi Ouzou",
    url: "https://www.easyzoom.com/embed/51a3f1a14ed5400e945f7317aa7b53ea"
  },
  {
    title:"Profil épidémiologique des travailleurs atteints du syndrome d'apnées obstructive du sommeil (SAOS) et impact cognitive",
    authors: "K.SI AHMED, R.HARCHOUF, A.ARIB-MEZDAD",
    url:"https://www.easyzoom.com/imageaccess/33356e27ff0345a9af364b661adab104"
  },
  {
    title: "Choc hypovolémique et convulsions chez un nourrisson, et si c’était une allergie alimentaire?",
    authors: "I.ZEMOURA,H.BOUCENNA,L.SEKFALI,F.BOUFEROUA,A.MOHANDOUSSAID,N.BOUTERFAS,K.N. BENHALLA (Béni-messous)",
    url: "https://www.easyzoom.com/embed/644d14041151468c8f79465e59353a06"
  },
]
export { posters }