import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  DesktopOutlined,
  EyeOutlined,
  FilePdfOutlined
} from "@ant-design/icons"

const { SubMenu } = Menu
export default function Sanofi() {
  const [modalVisible, setModalVisible] = useState(false)
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Sanofi"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/sanofi.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setModalVisible(true)}
            >
              Présentation
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href={"https://ubiquity.business/saaic/sanofi/1.pdf"}
                   download style={{ fontSize: 18 }}>Asthma</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Sanofi"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        <h3>Présentation</h3>
        <p>
          Algérie Sanofi est partenaire du parcours de santé des patients
          algériens depuis près de 30 ans et emploie en Algérie plus de 900
          collaborateurs. Nous sommes engagés aux côtés de tous les acteurs de
          santé à travers des partenariats de haut niveau dans de nombreux
          domaines : La mise à disposition de solutions de santé, la prévention,
          la promotion de la recherche, la formation médicale continue et
          l’intégration industrielle.
        </p>
        <p>
          Sanofi met à disposition des patients algériens des produits innovants
          dans plus d’une centaine de spécialités - le diabète, l’hypertension,
          la cardiologie, l’oncologie, la thrombose, la psychiatrie, la
          neurologie, la médecine générale, ainsi que dans le domaine des
          vaccins via Sanofi Pasteur et des maladies rares via Sanofi Genzyme.
        </p>
        <p>
          La vocation de Sanofi est d’accompagner celles et ceux confrontés à
          des difficultés de santé. Entreprise biopharmaceutique mondiale
          spécialisée dans la santé humaine, nous prévenons les maladies avec
          nos vaccins et proposons des traitements innovants. Nous accompagnons
          tant ceux qui sont atteints de maladies rares, que les millions de
          personnes souffrant d’une maladie chronique.
        </p>
        <p>
          Sanofi et ses plus de 100 000 collaborateurs dans 100 pays
          transforment l'innovation scientifique en solutions de santé partout
          dans le monde.
        </p>
      </Modal>
    </div>
  )
}