import React from "react"
import Seo from "../../seo"
import Posters from "./posters"

export default function PostersIndex() {
  return (
    <div>
      <Seo title={"E-Posters"} />
      <Posters />
    </div>
  )
}