import Expo1 from "../../images/360/expo1.jpg"
import Expo2 from "../../images/360/expo2.jpg"
import Expo3 from "../../images/360/expo3.jpg"
import Expo4 from "../../images/360/expo4.jpg"
import Expo5 from "../../images/360/expo5.jpg"
import Expo6 from "../../images/360/expo6.jpg"
import { expo1, expo2, expo3, expo4, expo5, expo6 } from "../hotspots/expo"

const commonConfig = {
  type: "equirectangular",
  hfov: 120,
  autoLoad: true,
  doubleClickZoom: true,
  uiText: {
    loadingLabel: "Chargement..."
  }
}
const config1 = {
  ...commonConfig,
  pitch: -18.5,
  yaw: 167.3,
  imageSource: Expo1,
  hotSpots: expo1
}

const config2 = {
  ...commonConfig,
  pitch: -21.5,
  yaw: 112,
  imageSource: Expo2,
  hotSpots: expo2
}

const config3 = {
  ...commonConfig,
  imageSource: Expo3,
  hotSpots: expo3
}

const config4 = {
  ...commonConfig,
  imageSource: Expo4,
  hotSpots: expo4
}

const config5 = {
  ...commonConfig,
  imageSource: Expo5,
  hotSpots: expo5
}

const config6 = {
  ...commonConfig,
  imageSource: Expo6,
  hotSpots: expo6
}
export { config1, config2, config3, config4, config5, config6 }