import React from "react"
import { Card, Col, Row, Tabs, Typography } from "antd"
import Seo from "../../seo"
import { speakers } from "../../../utils/speakers"
import Description from "./description"

const { Meta } = Card
const { Title } = Typography
const { TabPane } = Tabs
export default function SpeakersIndex() {
  return <div
    style={{ padding: 10, backgroundColor: "#F4F4F4", textAlign: "center" }}>
    <Seo title="Orateurs" />
    <Title>Nos Conférenciers</Title>
    <Row gutter={[20, 20]}>
      {speakers.map((speaker, key) => (
        <Col xs={{ span: 24 }} md={{ span: 4 }} key={key}>
          <Card
            hoverable
            style={{ width: "100%", height: "100%" }}
            cover={
              <img
                src={speaker.src}
                alt={speaker.name}
              />
            }
          >
            <Meta
              description={
                <>
                  <Title level={5}>
                    {speaker.name}
                  </Title>
                  <Description
                    bio={speaker.bio}
                    poste={speaker.poste}
                  />
                </>
              }
            />
          </Card>
        </Col>
      ))}
    </Row>
  </div>
}