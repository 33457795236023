import React, { useEffect } from "react"
import ReactPannellum from "react-pannellum"
import Expo1 from "../../../images/360/expo1.jpg"
import Seo from "../../seo"
import {
  config1,
  config2,
  config3,
  config4,
  config5,
  config6
} from "../../../utils/configs/expo"

export default function ExpoIndex() {
  
  useEffect(() => {
    setTimeout(() => {
      ReactPannellum.addScene("expo2", config2)
      ReactPannellum.addScene("expo3", config3)
      ReactPannellum.addScene("expo4", config4)
      ReactPannellum.addScene("expo5", config5)
      ReactPannellum.addScene("expo6", config6)
    })
  }, [])
  
  return (
    <>
      <Seo title={"Exposition"} />
      <ReactPannellum
        id="expo1"
        style={{
          width: "100%",
          height: "92vh"
        }}
        imageSource={Expo1}
        sceneId="expo1"
        config={config1}
      />
    </>
  )
}