import React, { useState } from "react"
import { Card, Col, Modal, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player"
import { communications } from "../../../utils/communications"

const { Meta } = Card
const { Title, Text } = Typography
export default function Communications() {
  const [modalInfos, setModalInfos] = useState({
    visible: false,
    url: "",
    title: ""
  })
  return (
    <div style={{ padding: 10, backgroundColor: "#F4F4F4" }}>
      <Row gutter={[10, 10]}>
        {communications.map((communication, key) => (
          <Col xs={{ span: 24 }} md={{ span: 8 }} key={key}>
            <Card
              onClick={() => setModalInfos({
                ...modalInfos,
                visible: true,
                url: communication.url,
                title: communication.title
              })}
              hoverable
              style={{ width: "100%", height: "100%" }}
              cover={
                <StaticImage
                  src={"../../../images/videothumbnail.jpg"}
                  alt={"a player image"}
                  objectFit={"contain"}
                />
              }
            >
              <Meta
                description={
                  <>
                    <Title level={5}>
                      {communication.title}
                    </Title>
                    <Text type="secondary">
                      {communication.speaker}
                    </Text>
                  </>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        width={"auto"}
        onCancel={() => setModalInfos({ ...modalInfos, visible: false })}
        title={<Title level={4}>{modalInfos.title}</Title>}
        visible={modalInfos.visible}
        centered
        footer={null}
        destroyOnClose
      >
        <ReactPlayer
          url={modalInfos.url}
          controls
          width={1024}
          height={576}
        />
      </Modal>
    </div>
  )
}