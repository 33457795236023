import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../../../seo"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Form, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FileImageOutlined, LinkOutlined, MailOutlined, PhoneOutlined,
  PlayCircleOutlined
} from "@ant-design/icons"

import ReactPlayer from "react-player"

const { SubMenu } = Menu
export default function Pierrefabre() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Pierre Fabre"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/pierrefabre.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setupModal("presentation")}
            >
              Présentation
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/pierrefabre/Pierre-Fabre-1.png"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Laboratoires</a>
              </Menu.Item>
              <Menu.Item icon={<FileImageOutlined />}>
                <a href="https://ubiquity.business/saaic/pierrefabre/Pierre-Fabre-2.png"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Primalan</a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              icon={<PlayCircleOutlined />}
              onClick={() => setupModal("video")}
            >
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Pierre Fabre"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <p>
      <strong>Pierre Fabre</strong> est un groupe pharmaceutique français,
      créé en 1962 par Pierre Fabre, un pharmacien castrais.
    </p>
    <p>
      Le groupe est particulièrement présent dans les domaines de la santé à la
      beauté et est majoritairement détenu par une fondation reconnue d'utilité
      publique, la Fondation Pierre Fabre à la suite d'une donation faite par
      son
      créateur en 2008. This fondation a pour mission d'améliorer l'accès aux
      soins et aux médicaments de qualité des populations des pays les moins
      avancés ainsi qu'aux populations exceptionnellement plongées dans des
      situations de crise grave.
    </p>
    <p>
      Le groupe Pierre Fabre est implanté dans 45 pays. Depuis toujours,
      l'ouverture vers l'international est au cœur de sa stratégie.
    </p>
  </div>

const video =
  <ReactPlayer
    url={"https://ubiquity.business/saaic/pierrefabre/v1.mp4"}
    controls
    width={1024}
    height={576}
  />

const contact =
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 770 11 47 69</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:yattoum@pierre-fabre.com">yattoum@pierre-fabre.com</a>
    </Form.Item>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://pierre-fabre.com" target="_blank">www.pierre-fabre.com</a>
    </Form.Item>
  </Form>