import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Form, Menu, Modal, Row } from "antd"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FileImageOutlined, LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  PlayCircleOutlined, UserOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"

export default function Takeda() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Takeda"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/takeda.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <Menu.Item
              icon={<PlayCircleOutlined />}
              onClick={() => setupModal("video")}
            >
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Takeda"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const video=
  <ReactPlayer
    url={"https://ubiquity.business/saaic/takeda/v1.mp4"}
    controls
    width={1024}
    height={576}
  />

const contact=
  <Form>
    <Form.Item label={<UserOutlined />}>
      <span>Kamel Merad (Medical Manager)</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:kamel.merad@takeda.com">kamel.merad@takeda.com</a>
    </Form.Item>
  </Form>
