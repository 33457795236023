import React from "react"
import Iframe from "react-iframe"

export default function Slido() {
  return (
    <Iframe
      url="https://app.sli.do/event/c0h2puxd"
      height="100%"
      width="100%"
      allowFullScreen
    />
  )
}