import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import "../../../../../style/navbar.css"
import { Col, Form, Menu, Modal, Row } from "antd"
import {
  ContactsOutlined,
  DesktopOutlined, LinkOutlined, MailOutlined, PhoneOutlined,
  PlayCircleOutlined,
  UserOutlined
} from "@ant-design/icons"
import "./inphamedis.css"
import ReactPlayer from "react-player"

const { SubMenu } = Menu
export default function Inphamedis() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Inphamedis"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/inphamedis.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setupModal("presentation")}
            >
              Présentation
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <Menu.Item
              icon={<PlayCircleOutlined />}
              onClick={() => setupModal("video")}
            >
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Inphamedis"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <div id="page_1">
      <p className="p0 ft1">Les laboratoires <nobr><span
        className="ft0">INPHA-MédiS</span></nobr><span className="ft0"> </span>sont
        une Société par Actions, au capital de 1.670.000.000 dinars Algériens.
      </p>
      <p className="p1 ft3">Elle s’étend sur une superficie de 14 000 m2. Est
        située à Sidi Kaçi Ben M’Hidi <nobr>-EL</nobr> tarf dont le siège social
        est à Annaba est spécialisée dans l’industrie pharmaceutique. Les
        laboratoires <nobr><span className="ft2">INPHA-MédiS</span>,
        </nobr> disposent d’installations et d’équipements de production
        réparties suivant trois départements :
      </p>
      <p className="p2 ft4"><span className="ft4">·</span><span className="ft5">Un département de fabrication des formes liquides (sirops et solutions buvables)</span>
      </p>
      <p className="p3 ft4"><span className="ft4">·</span><span className="ft5">Un département de fabrication dédié aux formes sèches (gélules et comprimés)</span>
      </p>
      <p className="p4 ft4"><span className="ft4">·</span><span className="ft5">Un département spécifique dédié aux conditionnements de produits stérile injectable</span>
      </p>
      <p className="p5 ft1">Les laboratoires <nobr>INPHA-MédiS</nobr> s’est
        lancé de façon active dans le partenariat avec les laboratoires médis
        (laboratoire tunisien) visant les objectifs suivants :
      </p>
      <p className="p6 ft4"><span className="ft4">·</span><span className="ft5">Augmentation de sa part du marché local ;</span>
      </p>
      <p className="p3 ft4"><span className="ft4">·</span><span className="ft5">Intégration du laboratoire dans le marché régional et international</span>
      </p>
      <p className="p4 ft4"><span className="ft4">·</span><span className="ft5">Transfert technologique et acquisition de </span>
        <nobr>savoir-faire</nobr>
      </p>
      <p className="p7 ft3"><span className="ft4">·</span><span className="ft6">L’élargissement de sa gamme de production et l’amélioration de leur qualité à des prix compétitifs.</span>
      </p>
      <p className="p8 ft4">Les laboratoires <nobr><span
        className="ft7">INPHA-Médis</span></nobr><span className="ft7"> </span>Aujourd’hui
        c’est plus de 500 collaborateurs,
      </p>
      <p className="p9 ft3"><span className="ft8">∙</span><span className="ft9">12 lignes de production ou de sites de 14000 m de surface avec une capacité de production de 175 millions d’unité par an</span>
      </p>
      <p className="p10 ft4"><span className="ft8">∙</span><span
        className="ft10">101 décisions d’enregistrement</span></p>
      <p className="p11 ft3"><span className="ft8">∙</span><span
        className="ft11">108 produits commercialisés dont 5 immunosuppresseurs indiqués dans la transplantation d’organe</span>
      </p>
      <p className="p12 ft12">
        <nobr>INPHA-Médis</nobr>
        c’est aussi 54 produits en développement et à l’enregistrement notamment
        les sérums, le injectables et les dispositifs pour inhalation
      </p>
      <p className="p13 ft4"><span className="ft8">∙</span><span
        className="ft13">08 formes galéniques dont les formes stériles injectables</span>
      </p>
      <p className="p14 ft3"><span className="ft8">∙</span><span
        className="ft9">14 domaines thérapeutiques à savoir : la cardiologie, la diabétologie, la neurologie, la pneumologie, l’allergologie et bien d’autres</span>
      </p>
      <p className="p10 ft4"><span className="ft8">∙</span><span
        className="ft10">Un laboratoire de contrôle qualité avec 35 collaborateurs qualifiés</span>
      </p>
      <p className="p15 ft4">ET en fin 130 délégués à votre service et au
        service de la santé.</p>
    </div>
    <div id="page_2">
      <p className="p16 ft14">Système de Management Intégré - SMI</p>
      <p className="p17 ft15">Nous, Laboratoires
        pharmaceutiques <nobr>INPHA-MédiS,</nobr> notre mission est le
        développement, fabrication conditionnement et commercialisation des
        médicaments à usage humain. Nous ambitionnons de renforcer notre
        position dans le système national de santé, à travers la mise à
        disposition aux patients de médicaments de qualité, sûrs et efficaces,
        tout en adoptant un management durable et responsable, qui nous engage à
        réaliser nos activités, dans le respect des exigences règlementaires,
        légales, normatives et BPF applicables tout en préservant la santé, la
        sécurité de notre personnel et l’environnement.
      </p>
      <p className="p18 ft4">Ainsi, pour garantir notre croissance et pérennité,
        nous devons conjuguer performance et développement durable en prenant en
        compte, de façon continue, nos forces/opportunités afin de faire face
        aux risques et menaces.</p>
      <p className="p19 ft4">Forts de nos acquis et de notre expérience, et en
        alignant notre stratégie sur celle de notre pays, en matière de
        développement <nobr>socio-économique</nobr> durable et de notre
        politique pharmaceutique nationale, nous visons un accroissement de
        notre capacité de production ainsi que l’élargissement de notre gamme
        thérapeutique.
      </p>
      <p className="p20 ft4">Nous soutenons et déployons notre engagement, à
        travers les axes suivants :</p>
      <p className="p21 ft7">1- Respecter les exigences légales, réglementaires,
        normatives et autres exigences applicables.</p>
      <p className="p22 ft7"><span className="ft4">2- </span>Accroitre nos parts
        de marché et être dans le top 5.</p>
      <p className="p23 ft7"><span className="ft4">3- </span>Améliorer nos
        outils de production suivant l’évolution technologique afin de maintenir
        la qualité de nos produits.</p>
      <p className="p24 ft7"><span className="ft4">4- </span>Satisfaire les
        exigences de nos clients, accroitre leur satisfaction et entretenir des
        relations de proximité et d'écoute permanente, avec nos partenaires et
        les autres parties intéressées jugées pertinentes.</p>
      <p className="p25 ft7">5- Améliorer en continu notre efficacité en
        promouvant l'approche processus et l'approche par les risques.</p>
      <p className="p26 ft7">6- Procurer des conditions de travail sûres et
        saines afin de prévenir les accidents de travail et les maladies
        professionnelles liés à nos activités et en lien avec les
        risques/opportunités associés, éliminer les dangers.</p>
      <p className="p27 ft7">7- Préserver l'environnement et prévenir toute
        pollution et contamination par l'utilisation optimale des ressources
        naturelles et la réduction de nos déchets, en tenant compte autant que
        possible du cycle de vie de nos produits.</p>
      <p className="p28 ft7">8- Développer les compétences, Consulter et faire
        participer le personnel, ses représentants, afin d'instaurer et soutenir
        un comportement sûr et éco responsable.</p>
      <p className="p29 ft4">Pour notre part, nous Direction, nous nous
        engageons à fournir les ressources</p>
      <p className="p30 ft4">nécessaires au maintien et à l’amélioration
        continue de l’efficacité de notre système de management.</p>
    </div>
  </div>

const video =
  <ReactPlayer
    url={"https://ubiquity.business/saaic/inphamedis/v1.mp4"}
    controls
    width={1024}
    height={576}
  />

const contact =
  <Form>
    <Form.Item label={<UserOutlined />}>
      <span>Yasser Asnam (Superviseur national gamme Pneumo-Allergo)</span>
    </Form.Item>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 5 60 00 86 39</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:yasser-asnam@inphamedis.dz">yasser-asnam@inphamedis.dz</a>
    </Form.Item>
    <Form.Item label={<UserOutlined />}>
      <span>Kribi Djamila (Chef de Produit gamme Pneumo- Allergo)</span>
    </Form.Item>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 5 55 00 90 72</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:kribi-djamila@inphamedis.dz">kribi-djamila@inphamedis.dz</a>
    </Form.Item>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://inphamedis.dz/" target="_blank">www.inphamedis.dz</a>
    </Form.Item>
  </Form>