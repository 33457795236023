import { navigate } from "gatsby-link"

const expo1 = [
  {
    type: "scene",
    pitch: -4.8,
    yaw: 160.8,
    text: "Aller ici",
    sceneId: "expo2"
  },
  {
    type: "scene",
    pitch: -1.9,
    yaw: 155.6,
    text: "Aller ici",
    sceneId: "expo3"
  },
  {
    type: "scene",
    pitch: -1.7,
    yaw: 176,
    text: "Aller ici",
    sceneId: "expo4"
  },
  {
    type: "scene",
    pitch: -20,
    yaw: -147,
    text: "Aller ici",
    sceneId: "expo5"
  },
  {
    type: "scene",
    pitch: -10,
    yaw: 122.7,
    text: "Aller ici",
    sceneId: "expo6"
  },
  {
    type: "scene",
    pitch: -0.8,
    yaw: 31,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: -1,
    yaw: 67.6,
    text: "Réception",
    clickHandlerFunc: () => navigate("/congres")
  },
  {
    type: "scene",
    pitch: -0.04,
    yaw: 90.6,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/communications")
  },
  {
    type: "scene",
    pitch: -0.11,
    yaw: 101.5,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "info",
    pitch: -22,
    yaw: -1,
    text: "Stand Bekker",
    clickHandlerFunc: () => navigate("/congres/expo/bekker")
  },
  {
    type: "info",
    pitch: -10.5,
    yaw: 108.2,
    text: "Stand Frater Razes",
    clickHandlerFunc: () => navigate("/congres/expo/fraterrazes")
  },
  {
    type: "info",
    pitch: -19,
    yaw: 145.8,
    text: "Stand Inphamedis",
    clickHandlerFunc: () => navigate("/congres/expo/inphamedis")
  },
  {
    type: "info",
    pitch: -18.5,
    yaw: -119.7,
    text: "Stand Genericlab",
    clickHandlerFunc: () => navigate("/congres/expo/genericlab")
  },
  {
    type: "info",
    pitch: -4.4,
    yaw: -138.5,
    text: "Stand Takeda",
    clickHandlerFunc: () => navigate("/congres/expo/takeda")
  },
  {
    type: "info",
    pitch: -11.8,
    yaw: -174.3,
    text: "Stand Novartis",
    clickHandlerFunc: () => navigate("/congres/expo/novartis")
  },
  {
    type: "info",
    pitch: -5,
    yaw: 120,
    text: "Stand Accentis",
    clickHandlerFunc: () => navigate("/congres/expo/accentis")
  }
]

const expo2 = [
  {
    type: "scene",
    pitch: -1.22,
    yaw: -49,
    text: "Aller ici",
    sceneId: "expo1"
  },
  {
    type: "scene",
    pitch: -20,
    yaw: 91.5,
    text: "Aller ici",
    sceneId: "expo3"
  },
  {
    type: "scene",
    pitch: -0.9,
    yaw: 135.14,
    text: "Aller ici",
    sceneId: "expo4"
  },
  {
    type: "scene",
    pitch: -13.75,
    yaw: -89.4,
    text: "Aller ici",
    sceneId: "expo5"
  },
  {
    type: "scene",
    pitch: -1.9,
    yaw: 40,
    text: "Aller ici",
    sceneId: "expo6"
  },
  {
    type: "scene",
    pitch: -0.5,
    yaw: -31.2,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: -0.5,
    yaw: -12,
    text: "Réception",
    clickHandlerFunc: () => navigate("/congres")
  },
  {
    type: "scene",
    pitch: -0.31,
    yaw: 10.6,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/communications")
  },
  {
    type: "scene",
    pitch: -0.17,
    yaw: 30,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "info",
    pitch: -11.33,
    yaw: -0.1,
    text: "Stand Frater Razes",
    clickHandlerFunc: () => navigate("/congres/expo/fraterrazes")
  },
  {
    type: "info",
    pitch: -7.2,
    yaw: -82.3,
    text: "Stand Genericlab",
    clickHandlerFunc: () => navigate("/congres/expo/genericlab")
  },
  {
    type: "info",
    pitch: -28.5,
    yaw: -114,
    text: "Stand Novartis",
    clickHandlerFunc: () => navigate("/congres/expo/novartis")
  },
  {
    type: "info",
    pitch: -21.4,
    yaw: -57.11,
    text: "Stand Sanofi",
    clickHandlerFunc: () => navigate("/congres/expo/sanofi")
  },
  {
    type: "info",
    pitch: -6.22,
    yaw: -116.3,
    text: "Stand Bausch & Lomb",
    clickHandlerFunc: () => navigate("/congres/expo/bauschlomb")
  },
  {
    type: "info",
    pitch: -23.75,
    yaw: 122.8,
    text: "Stand GSK",
    clickHandlerFunc: () => navigate("/congres/expo/gsk")
  },
  {
    type: "info",
    pitch: -7,
    yaw: 93.2,
    text: "Stand Chiesi",
    clickHandlerFunc: () => navigate("/congres/expo/chiesi")
  },
  {
    type: "info",
    pitch: -6.65,
    yaw: 164.37,
    text: "Stand Astrazeneca",
    clickHandlerFunc: () => navigate("/congres/expo/astrazeneca")
  },
  {
    type: "info",
    pitch: -24,
    yaw: 65,
    text: "Stand Pharmalliance",
    clickHandlerFunc: () => navigate("/congres/expo/pharmalliance")
  }
]

const expo3 = [
  {
    type: "scene",
    pitch: -0.13,
    yaw: -62,
    text: "Aller ici",
    sceneId: "expo1"
  },
  {
    type: "scene",
    pitch: -19,
    yaw: -92.5,
    text: "Aller ici",
    sceneId: "expo2"
  },
  {
    type: "scene",
    pitch: -15.5,
    yaw: 177,
    text: "Aller ici",
    sceneId: "expo4"
  },
  {
    type: "scene",
    pitch: -5.6,
    yaw: -92.3,
    text: "Aller ici",
    sceneId: "expo5"
  },
  {
    type: "scene",
    pitch: -13,
    yaw: 3.65,
    text: "Aller ici",
    sceneId: "expo6"
  },
  {
    type: "scene",
    pitch: -13,
    yaw: 3.65,
    text: "Aller ici",
    sceneId: "expo6"
  },
  {
    type: "scene",
    pitch: -0.5,
    yaw: -48.46,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: -0.5,
    yaw: -36,
    text: "Réception",
    clickHandlerFunc: () => navigate("/congres")
  },
  {
    type: "scene",
    pitch: -0.5,
    yaw: -16,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/communications")
  },
  {
    type: "scene",
    pitch: -0.5,
    yaw: 5.6,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "info",
    pitch: -5,
    yaw: -36.44,
    text: "Stand Frater Razes",
    clickHandlerFunc: () => navigate("/congres/expo/fraterrazes")
  },
  {
    type: "info",
    pitch: -11.55,
    yaw: -100.55,
    text: "Stand Novartis",
    clickHandlerFunc: () => navigate("/congres/expo/novartis")
  },
  {
    type: "info",
    pitch: -19.3,
    yaw: -128.2,
    text: "Stand GSK",
    clickHandlerFunc: () => navigate("/congres/expo/gsk")
  },
  {
    type: "info",
    pitch: -24.1,
    yaw: 112.8,
    text: "Stand Chiesi",
    clickHandlerFunc: () => navigate("/congres/expo/chiesi")
  },
  {
    type: "info",
    pitch: -7,
    yaw: -170.2,
    text: "Stand Astrazeneca",
    clickHandlerFunc: () => navigate("/congres/expo/astrazeneca")
  },
  {
    type: "info",
    pitch: -24.5,
    yaw: -71.7,
    text: "Stand Pharmalliance",
    clickHandlerFunc: () => navigate("/congres/expo/pharmalliance")
  },
  {
    type: "info",
    pitch: -13,
    yaw: 18.6,
    text: "Stand Accentis",
    clickHandlerFunc: () => navigate("/congres/expo/accentis")
  },
  {
    type: "info",
    pitch: -8.85,
    yaw: 168.5,
    text: "Stand Pierre Fabre",
    clickHandlerFunc: () => navigate("/congres/expo/pierrefabre")
  }
]

const expo4 = [
  {
    type: "scene",
    pitch: -0.16,
    yaw: -46.6,
    text: "Aller ici",
    sceneId: "expo1"
  },
  {
    type: "scene",
    pitch: -3.37,
    yaw: -43.2,
    text: "Aller ici",
    sceneId: "expo2"
  },
  {
    type: "scene",
    pitch: 16.5,
    yaw: 8,
    text: "Aller ici",
    sceneId: "expo3"
  },
  {
    type: "scene",
    pitch: -2.42,
    yaw: -65.3,
    text: "Aller ici",
    sceneId: "expo5"
  },
  {
    type: "scene",
    pitch: -3.8,
    yaw: 2.4,
    text: "Aller ici",
    sceneId: "expo6"
  },
  {
    type: "info",
    pitch: -20.5,
    yaw: 136.8,
    text: "Stand Pierre Fabre",
    clickHandlerFunc: () => navigate("/congres/expo/pierrefabre")
  },
  {
    type: "info",
    pitch: -12,
    yaw: -78,
    text: "Stand Biopharm",
    clickHandlerFunc: () => navigate("/congres/expo/biopharm")
  },
  {
    type: "info",
    pitch: -10.2,
    yaw: -107,
    text: "Stand Optimhal",
    clickHandlerFunc: () => navigate("/congres/expo/optimhal")
  },
  {
    type: "info",
    pitch: -27,
    yaw: -35.5,
    text: "Stand El Kendi",
    clickHandlerFunc: () => navigate("/congres/expo/elkendi")
  },
  {
    type: "info",
    pitch: -6,
    yaw: 12.38,
    text: "Stand Accentis",
    clickHandlerFunc: () => navigate("/congres/expo/accentis")
  },
  {
    type: "info",
    pitch: -25.3,
    yaw: -150,
    text: "Stand Astrazeneca",
    clickHandlerFunc: () => navigate("/congres/expo/astrazeneca")
  },
  {
    type: "info",
    pitch: -13.7,
    yaw: 30.4,
    text: "Stand Chiesi",
    clickHandlerFunc: () => navigate("/congres/expo/chiesi")
  },
  {
    type: "info",
    pitch: -2.41,
    yaw: -83.6,
    text: "Stand Bausch & Lomb",
    clickHandlerFunc: () => navigate("/congres/expo/bauschlomb")
  }
]

const expo5 = [
  {
    type: "scene",
    pitch: -12.5,
    yaw: 0.59,
    text: "Aller ici",
    sceneId: "expo1"
  },
  {
    type: "scene",
    pitch: -17.85,
    yaw: 83.74,
    text: "Aller ici",
    sceneId: "expo2"
  },
  {
    type: "scene",
    pitch: -10.2,
    yaw: 87.57,
    text: "Aller ici",
    sceneId: "expo3"
  },
  {
    type: "scene",
    pitch: -1.25,
    yaw: 122.7,
    text: "Aller ici",
    sceneId: "expo4"
  },
  {
    type: "scene",
    pitch: -1.75,
    yaw: 55,
    text: "Aller ici",
    sceneId: "expo6"
  },
  {
    type: "scene",
    pitch: -0.8,
    yaw: -6.3,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: -0.26,
    yaw: 14.2,
    text: "Réception",
    clickHandlerFunc: () => navigate("/congres")
  },
  {
    type: "scene",
    pitch: -0.12,
    yaw: 33.3,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/communications")
  },
  {
    type: "scene",
    pitch: -0.32,
    yaw: 46.2,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "info",
    pitch: -7.6,
    yaw: -15.8,
    text: "Stand Beker",
    clickHandlerFunc: () => navigate("/congres/expo/beker")
  },
  {
    type: "info",
    pitch: -21,
    yaw: -42.26,
    text: "Stand Genericlab",
    clickHandlerFunc: () => navigate("/congres/expo/genericlab")
  },
  {
    type: "info",
    pitch: -8,
    yaw: -168,
    text: "Stand Takeda",
    clickHandlerFunc: () => navigate("/congres/expo/takeda")
  },
  {
    type: "info",
    pitch: -28.4,
    yaw: 123.7,
    text: "Stand Novartis",
    clickHandlerFunc: () => navigate("/congres/expo/novartis")
  },
  {
    type: "info",
    pitch: -21.3,
    yaw: 50,
    text: "Stand Sanofi",
    clickHandlerFunc: () => navigate("/congres/expo/sanofi")
  },
  {
    type: "info",
    pitch: -15.45,
    yaw: -144.5,
    text: "Stand Bausch & Lomb",
    clickHandlerFunc: () => navigate("/congres/expo/bauschlomb")
  },
  {
    type: "info",
    pitch: -8.46,
    yaw: 77.8,
    text: "Stand Pharmalliance",
    clickHandlerFunc: () => navigate("/congres/expo/pharmalliance")
  },
  {
    type: "info",
    pitch: -13.4,
    yaw: 159.2,
    text: "Stand Biopharm",
    clickHandlerFunc: () => navigate("/congres/expo/biopharm")
  },
  {
    type: "info",
    pitch: -8.4,
    yaw: 168.7,
    text: "Stand Optimhal",
    clickHandlerFunc: () => navigate("/congres/expo/optimhal")
  }
]
const expo6 = [
  {
    type: "scene",
    pitch: -4.7,
    yaw: 76.5,
    text: "Aller ici",
    sceneId: "expo1"
  },
  {
    type: "scene",
    pitch: -12.76,
    yaw: 27.2,
    text: "Aller ici",
    sceneId: "expo2"
  },
  {
    type: "scene",
    pitch: -11.8,
    yaw: -18,
    text: "Aller ici",
    sceneId: "expo3"
  },
  {
    type: "scene",
    pitch: -7.7,
    yaw: -15.6,
    text: "Aller ici",
    sceneId: "expo4"
  },
  {
    type: "scene",
    pitch: -0.64,
    yaw: 105.2,
    text: "Auditorium",
    clickHandlerFunc: () => navigate("/congres/auditorium")
  },
  {
    type: "scene",
    pitch: -1.28,
    yaw: 116.6,
    text: "Réception",
    clickHandlerFunc: () => navigate("/congres")
  },
  {
    type: "scene",
    pitch: -3.33,
    yaw: 160.7,
    text: "Communications Orales",
    clickHandlerFunc: () => navigate("/congres/communications")
  },
  {
    type: "scene",
    pitch: -1.9,
    yaw: -132.5,
    text: "E-Posters",
    clickHandlerFunc: () => navigate("/congres/posters")
  },
  {
    type: "info",
    pitch: -19,
    yaw: 7.55,
    text: "Stand Magpharm",
    clickHandlerFunc: () => navigate("/congres/expo/magpharm")
  },
  {
    type: "info",
    pitch: -17.5,
    yaw: -71.5,
    text: "Stand Accentis",
    clickHandlerFunc: () => navigate("/congres/expo/accentis")
  },
  {
    type: "info",
    pitch: -8,
    yaw: -29.6,
    text: "Stand Chiesi",
    clickHandlerFunc: () => navigate("/congres/expo/chiesi")
  },
  {
    type: "info",
    pitch: -9.5,
    yaw: 47.2,
    text: "Stand Inphamedis",
    clickHandlerFunc: () => navigate("/congres/expo/chiesi")
  },
  {
    type: "info",
    pitch: -24,
    yaw: 66.2,
    text: "Stand Frater Razes",
    clickHandlerFunc: () => navigate("/congres/expo/fraterrazes")
  }
]
export { expo1, expo2, expo3, expo4, expo5, expo6 }