import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Form, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  ContactsOutlined,
  DesktopOutlined, LinkOutlined, MailOutlined, PhoneOutlined,
  PlayCircleOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"

export default function Elkendi() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"El Kendi"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/elkendi.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item icon={<DesktopOutlined className={"icon2"} />} onClick={() => setupModal("presentation")}>
              Présentation
            </Menu.Item>
            <Menu.Item icon={<ContactsOutlined className={"icon2"} />} onClick={() => setupModal("contact")}>
              Contact
            </Menu.Item>
            <Menu.Item icon={<PlayCircleOutlined />} onClick={() => setupModal("video")}>
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"El Kendi"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation=
  <div>
    <p>
      El Kendi, leader dans le domaine de la production nationale pharmaceutique, existe depuis 2009 avec une forte volonté de contribuer à apporter aux patients des médicaments de haute qualité à des prix abordables.
    </p>
    <p>
      El Kendi est l’un des premiers laboratoires privés à avoir choisi de se lancer dans la production de médicaments en cheminement complet en Algérie.
    </p>
    <p>
      La production annuelle de médicaments d’El Kendi sous toutes ses formes dépasse les 50 millions d’unités par an, avec pour ambition d’innover constamment grâce à son département Recherche et Développement afin de répondre aux besoins de soins du plus grand nombre.
    </p>
    <p>
      Pionnier dans la production locale de médicaments pour les maladies chroniques, El Kendi produit à date 192 produits dans différents domaines médicaux tels que la cardiologie, la neuropsychiatrie, l’urologie, l’asthme et les maladies respiratoires, l’oncologie et les maladies auto-immunes.
    </p>
    <p>
      Avec plus de 1000 collaborateurs, El Kendi tire sa force de la jeunesse et des compétences de ses équipes dynamiques et engagées, dédiées entièrement à la satisfaction de la communauté.
    </p>
    <p>
      Dans cette optique de satisfaction, El Kendi investit dans la formation médicale continue ainsi que dans l’accompagnement des professionnels de la santé.
    </p>
  </div>

const contact=
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>023 07 73 30</span>
    </Form.Item>
    <Form.Item label={<PhoneOutlined />}>
      <span>023 07 73 31</span>
    </Form.Item>
    <Form.Item label={<PhoneOutlined />}>
      <span>07 70 25 30 00</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:communication_elk@elkendi.com">communication_elk@elkendi.com</a>
    </Form.Item>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://www.elkendi.com/" target="_blank">www.elkendi.com</a>
    </Form.Item>
  </Form>

const video=
  <ReactPlayer
    url={"https://ubiquity.business/saaic/elkendi/v1.mp4"}
    controls
    width={1024}
    height={576}
  />