import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Form, Menu, Modal, Row } from "antd"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FilePdfOutlined, LinkOutlined, MailOutlined, PhoneOutlined,
  PlayCircleOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"

const { SubMenu } = Menu
export default function Biopharm() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("contact")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Biopharm"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/biopharm.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
            >
              <a
                href="https://ubiquity.business/saaic/biopharm/presentation.pdf"
                target="_blank" download
                style={{ fontSize: 18 }}>Présentation</a>
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/1.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Célétasone</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/2.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Rynza Nasal</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/3.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Rynza Fumigation</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/4.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Nazalast</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/6.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Nazalast Poso</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/5.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Mexam</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/biopharm/rcp1.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>RCP Mexam</a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              icon={<PlayCircleOutlined />}
              onClick={() => setupModal("video")}
            >
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Biopharm"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const video =
  <ReactPlayer
    url={"https://ubiquity.business/saaic/biopharm/v1.mov"}
    controls
    width={1024}
    height={576}
  />

const contact =
  <Form>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://www.biopharmdz.com/index.php/fr/" target="_blank">www.biopharmdz.com</a>
    </Form.Item>
  </Form>