import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Form, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FileImageOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined
} from "@ant-design/icons"

const { SubMenu } = Menu
export default function Chiesi() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Chiesi"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/chiesi.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setupModal("presentation")}
            >
              Présentation
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FileImageOutlined />}>
                <a
                  href="https://ubiquity.business/saaic/chiesi/1.jpg"
                  target="_blank" download
                  style={{ fontSize: 18 }}>Foster</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Chiesi"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <h3>Le Groupe <strong>CHIESI</strong></h3>
    <p>
      L'histoire du Groupe Chiesi remonte au 6 juillet35 quand Giacomo Chiesi,
      pharmacien rêvant de recherche, acheta un laboratoire pharmaceutique à
      Parme pour son rêve.
    </p>
    <p>
      Le petit laboratoire de province – qui employait deux salariés à ses
      débuts – est devenu une entreprise internationale de plus de 5000
      salariés.
    </p>
    <p>
      Ce parcours, fondé sur la détermination et la passion de la famille Chiesi
      pour la recherche, transforma l'idée de départ en une entreprise au
      développement permanent.
    </p>
    <p>
      A la fin des années 1970, alors que la première filiale étrangère ouvrait
      au Brésil, l'entreprise développée sa dimension internationale pour
      devenir un groupe présent sur les 5 continents, à travers ses 27 filiales,
      et dont les médicaments sont désormais distribués avec succès dans plus de
      80 pays.
    </p>
    <p>
      L'histoire de Chiesi est l'histoire d'un rêve personnel devenu un rêve
      collectif: l'innovation créative avec objectif la mise à disposition de
      traitements efficaces.
    </p>
    <p>
      Avec plus de 80 ans d'existence Chiesi continue de faire vivre ce principe
      chaque jour avec passion et détermination
    </p>
    <h3>Aires d'expertise CHIESI</h3>
    <h4>
      RESPIRATOIRE
    </h4>
    <p>
      Le groupe Chiesi est pleinement engagé dans les soins et l'amélioration de
      la qualité de la vie des personnes souffrant de maladies respiratoires
      telles que l'asthme et la BPCO (bronchopneumopathie chronique
      obstructive). <br />
      Selon les estimations de l'Organisation Mondiale de la Santé (OMS), des
      millions de personnes à travers le monde - principalement des enfants -
      dernières d'asthme, que 64 millions de personnes de BPCO (estimation de
      l'OMS de 2004), qui devrait devenir la troisième principale cause de
      mortalité d'ici à 2030. Si des mesures visant à réduire les facteurs de
      risque liés à la maladie – à savoir la fumée de cigarette - ne sont pas
      adoptées, on estime que les décès liés à la BPCO augmenteront de 30 % au
      cours des 10 années futures. <br />
      Dans la recherche d'une amélioration continue de la santé des patients, le
      Chiesi recherche et développe de nouvelles solutions thérapeutiques la
      recherche sur la technologie de formulation innovante Modulite®, qui
      permet la création de solutions pour inhalation de particules permettant
      d'administrer des extra- amendes. Le groupe Chiesi a également breveté et
      introduit sur le marché un inhalateur à base de poudre sèche (IPS), qui
      permet également d'administrer des particules extra-fines.
    </p>
    <h4>
      NÉONATOLOGIE
    </h4>
    <p>
      Les objectifs de la réanimation en salle de naissance sont d'assurer une
      ventilation efficace et de maintenir un bon équilibre hémodynamique. La
      réanimation respiratoire est prioritaire. <br />
      Parmi les recommandations particulières à la prise en charge de l'extrême
      prématurée de l'administration précoce de surfactant, ainsi qu'une
      oxygénation initiale. <br />
      La priorité de la réanimation respiratoire fait aujourd'hui l'unanimité
      dans les premières minutes de vie. Le développement des méthodes de
      ventilation non invasive et l'utilisation précoce d'un surfactant exogène
      naturel sont les deux progrès les plus récents pour la prise en charge
      précoce du grand prématuré en salle de naissance.
    </p>
    <h4>
      TRANSPLANTATION
    </h4>
    <p>
      Pour répondre à cette problématique, le Laboratoire Chiesi met à
      disposition son expertise médicale et sa capacité d'innovation au service
      des patients transplantés. Et c'est donc dans l'optique d'améliorer la
      qualité de vie de ces patients que le Laboratoire Chiesi a décidé de
      s'associer à un autre laboratoire pharmaceutique pour mettre en commun
      leurs connaissances scientifiques et offrir une nouvelle alternative
      thérapeutique aux patients .Ainsi, un partenariat a été créé entre Veloxis
      Pharmaceuticals et Chiesi afin de mettre à disposition des patients
      transplantés la technologie MeltDose®.
    </p>
    <h4>
      MALADIES RARES
    </h4>
    <p>
      Les maladies rares créent des problèmes spécifiques liés à leur rareté.
      Graves, souvent chroniques et parfois progressives, elles peuvent
      apparaître à la naissance ou pendant l'enfance. Toutes les personnes
      affectées par les maladies rares ont du mal à obtenir un diagnostic, des
      informations ou encore une référence pour consulter des spécialistes
      compétents. Il est tout aussi difficile d'avoir accès à un traitement
      efficace, de recevoir des soins sociaux et médicaux pour leur maladie, de
      gérer le traitement de base avec celui fourni par les hôpitaux, et de
      maintenir leur autonomie et leur intégration sociale, professionnelle et
      civique. Cela explique pourquoi nous nous concentrons particulièrement sur
      le traitement et l'assistance aux personnes souffrant de maladies rares,
      dont la grossesse est compromise. <br />
      Nos efforts sont concentrés sur le traitement de trois maladies rares :
      <ul>
        <li>CYSTINOSE NÉPHROPATIQUE</li>
        <li>DÉFICIT EN CELLULES SOUCHES LIMBIQUES (DCSL)</li>
        <li>ALPHA-MANNOSIDOSE</li>
      </ul>
    </p>
  </div>


const contact =
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 5 61 61 06 65</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a
        href="mailto:sadeg.benyoussef@theramedic.dz">sadeg.benyoussef@theramedic.dz</a>
    </Form.Item>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://chiesi.fr/" target="_blank">www.chiesi.fr</a>
    </Form.Item>
  </Form>