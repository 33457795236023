import React from "react"
import { Layout } from "antd"
import HeaderContent from "./header"

const { Header, Content } = Layout
export default function CongresLayout(props) {
  const { children } = props
  
  return (
    <Layout>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          backgroundColor: "#72151A",
          height: "8vh",
          textAlign: "center"
        }}
        id={"header"}
      >
        <HeaderContent />
      </Header>
      <Content style={{ backgroundColor: "white" }}>
        <div style={{ height: "8vh" }} />
        {children}
      </Content>
    </Layout>
  )
}