import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../../../../../style/navbar.css"
import { Col, Form, Menu, Modal, Row } from "antd"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FilePdfOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  PlayCircleOutlined
} from "@ant-design/icons"
import ReactPlayer from "react-player"
const {SubMenu} = Menu
export default function Fraterrazes() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Fraterrazes"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/fraterrazes.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
            >
              <a href="https://ubiquity.business/saaic/frater/5.pdf"
                 target="_blank" download
                 style={{ fontSize: 18 }}>Présentation</a>
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/frater/1.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Levofloxacine</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/frater/2.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Razifer</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/frater/3.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Supramadol</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href="https://ubiquity.business/saaic/frater/4.pdf"
                   target="_blank" download
                   style={{ fontSize: 18 }}>Varenox</a>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              icon={<PlayCircleOutlined />}
              onClick={() => setupModal("video")}
            >
              Video
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Frater Razes"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "video" && video}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const video=
  <ReactPlayer
    url={"https://ubiquity.business/saaic/frater/v1.mp4"}
    controls
    width={1024}
    height={576}
  />
const contact=
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213 (0) 23 53 81 19</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a
        href="mailto:contact@frater-razes.com">contact@frater-razes.com</a>
    </Form.Item>
    <Form.Item label={<LinkOutlined />}>
      <a href="https://frater-razes.com/" target="_blank">www.frater-razes.com</a>
    </Form.Item>
  </Form>