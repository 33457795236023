import React, { useEffect, useState } from "react"
import { Menu } from "antd"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import {
  AppstoreOutlined,
  CalendarOutlined,
  DesktopOutlined,
  HomeOutlined,
  IdcardOutlined,
  PictureOutlined,
  ShopOutlined
} from "@ant-design/icons"
import { getSelectedMenu } from "../../../utils/paths"
import "../../../style/navbar.css"

const { SubMenu } = Menu
export default function HeaderContent() {
  const location = useLocation()
  const [selectedKey, setSelectedKey] = useState("1")
  
  useEffect(() => {
    const key = getSelectedMenu(location.pathname)
    setSelectedKey(key)
  }, [location])
  
  return (
    <Menu
      theme="dark"
      style={{
        backgroundColor: "#72151A",
        height: "8vh",
        textAlign: "center",
        fontSize: 18
      }}
      mode="horizontal"
      selectedKeys={[selectedKey]}
      id="navbar"
      ononSelect={(_, key) => setSelectedKey(key)}
    >
      <Menu.Item key="1" icon={<AppstoreOutlined className={"icon"} />}>
        <Link to="/congres/">Lobby</Link>
      </Menu.Item>
      <Menu.Item key="6" icon={<CalendarOutlined className={"icon"} />}>
        <Link to={"/congres/program"}>Programme</Link>
      </Menu.Item>
      <Menu.Item key="7" icon={<IdcardOutlined className={"icon"} />}>
        <Link to={"/congres/speakers"}>Conférenciers</Link>
      </Menu.Item>
      <Menu.Item key="4" icon={<DesktopOutlined className={"icon"} />}>
        <Link to="/congres/auditorium">Auditorium</Link>
      </Menu.Item>
      <SubMenu
        style={{
          backgroundColor: "#72151A",
        }}
        key="3"
        icon={<ShopOutlined className={"icon"} />}
        title="Exposition Pharmaceutique"
      >
        <Menu.Item>
          <Link to="/congres/expo">Hall d'exposition</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/accentis">Accentis</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/astrazeneca">Astrazeneca</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/bauschlomb">Bausch & Lomb</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/beker">Beker</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/biopharm">Biopharm</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/chiesi">Chiesi</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/elkendi">El Kendi</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/fraterrazes">Frater Razes</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/genericlab">Genericlab</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/gsk">GSK</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/inphamedis">Inphamedis</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/magpharm">Magpharm</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/novartis">Novartis</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/optimhal">Optimhal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/pharmalliance">Pharmalliance</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/pierrefabre">Pierre Fabre</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/sanofi">Sanofi</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/congres/expo/takeda">Takeda</Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="8" icon={<PictureOutlined className={"icon"} />}>
        <Link to={"/congres/communications"}>Communications Orales</Link>
      </Menu.Item>
      <Menu.Item key="5" icon={<PictureOutlined className={"icon"} />}>
        <Link to={"/congres/posters"}>E-Posters</Link>
      </Menu.Item>
    </Menu>
  )
}