import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Form, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  ContactsOutlined,
  DesktopOutlined,
  EyeOutlined,
  FilePdfOutlined, LinkOutlined, MailOutlined, PhoneOutlined
} from "@ant-design/icons"

const { SubMenu } = Menu

export default function Genericlab() {
  const [modalVisible, setModalVisible] = useState(false)
  const [content, setContent] = useState("presentation")
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  function setupModal(content) {
    setContent(content)
    setModalVisible(true)
  }
  
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"Genericlab"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/genericlab.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item
              icon={<DesktopOutlined className={"icon2"} />}
              onClick={() => setupModal("presentation")}
            >
              Présentation
            </Menu.Item>
            <Menu.Item
              icon={<ContactsOutlined className={"icon2"} />}
              onClick={() => setupModal("contact")}
            >
              Contact
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href={"https://ubiquity.business/saaic/genericlab/1.pdf"}
                   target="_blank" download style={{ fontSize: 18 }}>Dolpina</a>
              </Menu.Item>
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href={"https://ubiquity.business/saaic/genericlab/4.pdf"}
                   target="_blank" download
                   style={{ fontSize: 18 }}>Produits</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"Genericlab"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        {content === "presentation" && presentation}
        {content === "contact" && contact}
      </Modal>
    </div>
  )
}

const presentation =
  <div>
    <p>
      GENERIC LAB est un établissement pharmaceutique algérien créé en 1992.
      C'est l'un des premiers laboratoires à s'être lancé dans la production de
      médicaments.
    </p>
    <p>
      Implanté à Rouiba dans la plus grande Zone Industrielle en Algérie, notre
      site a entièrement été conçu aux normes BPF et ISO.
    </p>
    <p>
      Près de 300 personnes hautement qualifiées travaillent dans le strict
      respect des procédures de fabrication.
    </p>
    <p>
      GENERICLAB a su s'entourer de partenaires nationaux et internationaux
      (Suisses, Italiens, Allemands, Espagnols et indiens) afin d'être à la
      pointe du développement.
    </p>
    <p>
      GENERICLAB, c'est aussi : plus de 40 produits de spécialités diverses
      (Allergologie, Ophtalmologie, Pédiatrie, Cardiologie, Psychiatrie,
      Gastro-entérologie, Rhumatologie, Orthopédie et Urologie).
    </p>
    <p>
      Une large gamme de produits répondant aux besoins de vos patients.
    </p>
  </div>
const contact =
  <Form>
    <Form.Item label={<PhoneOutlined />}>
      <span>+213(0)23 85 00 23</span>
    </Form.Item>
    <Form.Item label={<MailOutlined />}>
      <a href="mailto:info@genericlab.com">info@genericlab.com</a>
    </Form.Item>
  </Form>