import React, { useState } from "react"
import Seo from "../../../../seo"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Col, Menu, Modal, Row } from "antd"
import "../../../../../style/navbar.css"
import {
  DesktopOutlined,
  EyeOutlined,
  FilePdfOutlined
} from "@ant-design/icons"

const { SubMenu } = Menu
export default function Gsk() {
  const [modalVisible, setModalVisible] = useState(false)
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  return (
    <div style={{ display: "grid" }}>
      <Seo title={"GSK"} />
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "92vh", filter: "blur(8px)" }}
      />
      <StaticImage
        style={{
          gridArea: "1/1",
          maxHeight: "92vh"
        }}
        src={"../../../../../images/stands/gsk.jpg"}
        alt={"a stand image"}
        objectFit={"contain"}
      />
      <Row
        style={{
          gridArea: "1/1",
          maxHeight: "92vh",
          width: "100%"
        }}
        justify="center"
        align="bottom"
      >
        <Col>
          <Menu style={{ textAlign: "center", fontSize: 20 }} mode="horizontal">
            <Menu.Item icon={<DesktopOutlined className={"icon2"} />}>
              Présentation
            </Menu.Item>
            <SubMenu
              key="1"
              icon={<EyeOutlined className={"icon2"} />}
              title="Visuels"
            >
              <Menu.Item icon={<FilePdfOutlined />}>
                <a href={"https://ubiquity.business/saaic/gsk/1.pdf"}
                   target="_blank" download style={{ fontSize: 18 }}
                >Avamys</a>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
      <Modal
        title={"GSK"}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
        width="auto"
        centered
        footer={null}
      >
        <p>
          GSK continue de contribuer au développement de la santé en Algérie
          depuis de nombreuses années (35 ans) et un acteur particulièrement
          actif en matière d'industrie pharmaceutique locale.
        </p>
        <p>
          GSK est fière de fabriquer plus de 60% de son portefeuille dans son
          usine en Algérie et de fournir des médicaments innovants de haute
          qualité aux patients.
        </p>
        <p>
          GSK emploi 393 salariés : biologistes, chimistes, pharmaciens et
          ingénieurs algériens hautement qualifiés. Au-delà de cela, GSK
          soutient la formation des pharmaciens, pneumologues et autres types de
          spécialistes algériens afin de garantir que les médecins algériens
          bénéficient des dernières informations sur nos médicaments et les
          maladies qu'ils reçoivent.
        </p>
      </Modal>
    </div>
  )
}